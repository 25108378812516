import { useEffect } from 'react';
import { useOutletContext } from "react-router-dom";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const History = () => {
    const [userIDData, options, changeRoute, eCommerceList] = useOutletContext();

    useEffect(() => {
        let scrollPosition = sessionStorage.getItem('historyScrollPosition');
        if(scrollPosition){
            scrollPosition = JSON.parse(scrollPosition);
            document.getElementsByClassName("table-div-2")[0].scrollTo(parseInt(scrollPosition.x, 10), parseInt(scrollPosition.y, 10));
            sessionStorage.removeItem('historyScrollPosition');
        }
    }, []);

    return ( 
        <div className='timeline-outer-div'>
            <VerticalTimeline animate={true} lineColor='#d0fad4' layout='2-columns'>
                {
                    userIDData && userIDData.map((data, index) => {
                        let date = new Date(data.created_at).toLocaleTimeString("en-us", options);
                        date = date == 'Invalid Date' ? data.created_at : date;
                        return (
                            <VerticalTimelineElement key={`${data.id}_${index}`} className="vertical-timeline-element--work" contentStyle={{ background: '#d0fad4' }} contentArrowStyle={{ borderRight: '7px solid  #d0fad4' }} date={date} iconStyle={{ background: '#25ab9d', color: '#fff', borderColor: '#d0fad4' }} iconClassName={ data.connected_partners.length == 0 && data.incomplete_partners.length == 0 ? '' : 'show-cursor-history' } iconOnClick={() => (data.connected_partners.length || data.incomplete_partners.length) && changeRoute('id', data.id)}>
                                {
                                    data.connected_partners.length > 0 && (
                                        <div className='timeline-block'>
                                            <h4 className="vertical-timeline-element-subtitle">Successful connections</h4>
                                            <div className='timeline-partners-name'>
                                            {
                                                data.connected_partners.map((partner, index) => {
                                                    let partnerName = Array.isArray(partner) ? partner[0] : partner;
                                                    const eCommerce = eCommerceList.find(eCommerce => eCommerce.code == partnerName);
                                                    partnerName = eCommerce ? eCommerce.displayName : partnerName.charAt(0).toUpperCase() + partnerName.slice(1);
                                                    return (
                                                        <span key={partnerName}>
                                                            {`${partnerName}${index < data.connected_partners.length - 1 ? ', ' : ''}`}
                                                        </span>
                                                    );
                                                })
                                            }
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    data.incomplete_partners.length > 0 && (
                                        <div className={`timeline-block-incomplete ${!data.connected_partners.length ? 'timeline-block-incomplete-top' : ''}`}>
                                            <h4 className="vertical-timeline-element-subtitle">Failed connections</h4>
                                            <div className='timeline-partners-name'>
                                            {
                                                data.incomplete_partners.map(partner => {
                                                    let partnerName = Array.isArray(partner) ? partner[0] : partner;
                                                    let errorMessage = Array.isArray(partner) ? partner[1] : undefined;
                                                    const eCommerce = eCommerceList.find(eCommerce => eCommerce.code == partnerName); // typeof errorMessage == 'string' ? errorMessage : JSON.stringify(errorMessage)
                                                    partnerName = eCommerce ? eCommerce.displayName : partnerName.charAt(0).toUpperCase() + partnerName.slice(1);
                                                    errorMessage = eCommerce && eCommerce.getErrorMessage ? eCommerce.getErrorMessage(errorMessage) : 'Authorization Incomplete';
                                                    if(typeof errorMessage == 'object' && errorMessage !== null){
                                                        return (
                                                            <div key={partnerName} className='error-msg-object'>
                                                                <div className='error-msg-object-key'> {partnerName}:&nbsp; </div>
                                                                <div>
                                                                    {
                                                                        Object.keys(errorMessage).map(key => {
                                                                            return (
                                                                                <div key={key}>
                                                                                    {key} - {errorMessage[key]}
                                                                                </div>
                                                                            );
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                    return (
                                                        <div key={partnerName} className='error-msg-object'>
                                                            <div className='error-msg-object-key'> {partnerName}:&nbsp; </div>
                                                            <div> {errorMessage} </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    data.connected_partners.length == 0 && data.incomplete_partners.length == 0 && (
                                        <div>
                                            No Connection
                                        </div>
                                    ) 
                                }
                            </VerticalTimelineElement>
                        )
                    })
                }
            </VerticalTimeline>
        </div>
     );
}
 
export default History;