import axios from 'axios';

export const getDigitalFootPrint = async (id, phone) => {
    const isProduction = window.location.href.includes('https://dashboard.powercred.io') || window.location.href.includes('wqzd4eryoa');
    const baseURL = isProduction ? 'https://dev.powercred.io' : 'https://mock.powercred.io';
    const apikey = new URLSearchParams(window.location.search).get('apikey');
    try{
        const response = await axios.post(`${baseURL}/identity/telco/insights?apikey=${apikey}&id=${id}&phone_number=${phone}`);
        return response.data;
    } catch (error) {
        return error;
    }
}