import axios from 'axios';

const isProduction = window.location.href.includes('https://dashboard.powercred.io') || window.location.href.includes('wqzd4eryoa');
const baseURL = isProduction ? 'https://dev.powercred.io' : 'https://mock.powercred.io';

const wantLocal = false; // Mandatory to set it false before pushing it to gitlab

export const checkAvailability = async (id) => {
    const apikey = new URLSearchParams(window.location.search).get('apikey');
    try{
        const response = await axios.get(`${baseURL}/data/get/gopay/${id}?apikey=${apikey}`);
        return response.data;
    } catch { 
        return false;
    }
}

export const verifyAddress = async (id, address) => {
    const apikey = new URLSearchParams(window.location.search).get('apikey');
    const availabilityResponse = await checkAvailability(id);
    if (availabilityResponse !== false) {
        try{
            const url = wantLocal ? `http://localhost:8000/verify/address?apikey=${apikey}&id=${id}` : `${baseURL}/gopay/verify/address?apikey=${apikey}&id=${id}`;
            const response = await axios.post(url, [address]);
            return response.data;
        } catch (error) {
            return false;
        }
    } else {
        return false;
    }
}