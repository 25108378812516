import { useState } from "react";
import { useSearchParams, Outlet, useNavigate, useParams, useLocation } from 'react-router-dom';
import ListAccount from "./ListAccounts";
import './Home.css';

const Backoffice = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const { userid, id } = useParams();
    
    const location = useLocation();

    const navigate = useNavigate();

    const isIDDetail = id;
    
    const isHistory = !isIDDetail && location.pathname.split('/').pop() == 'history';

    const isUserID = !isIDDetail && !isHistory;

    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortedColumn, setSortedColumn] = useState({ column: null, isAscending: true });
    const limit = 25;

    const isProduction = window.location.href.includes('https://dashboard.powercred.io') || window.location.href.includes('wqzd4eryoa');
    const baseURL = isProduction ? 'https://dev.powercred.io' : 'https://mock.powercred.io';
  
    const options = { year: "numeric", month: "short", day: "numeric", hour: "2-digit", minute: "2-digit" }; 
    
    const changeRoute = (path, value) => {
        const searchQP = `apikey=${searchParams.get('apikey')}&developer_email=${searchParams.get('developer_email')}&app_id=${searchParams.get('app_id')}`;
        if(path == 'history' || (!path && isIDDetail)){
            navigate(`/${userid}/history?${searchQP}`);
        }
        else if(path == 'userid' || (!path && isHistory)){
            // isHistory && sessionStorage.setItem('historyScrollPosition', JSON.stringify({x: document.getElementsByClassName("table-div-2")[0].scrollLeft, y: document.getElementsByClassName("table-div-2")[0].scrollTop}));
            navigate(`/${userid}?${searchQP}`);
        }
        else if(path == 'userid-v'){
            sessionStorage.setItem('scrollPosition', JSON.stringify({x: document.getElementsByClassName("table-div-2")[0].scrollLeft, y: document.getElementsByClassName("table-div-2")[0].scrollTop}));
            navigate(`/${value}?${searchQP}`);
        }
        else if(path == 'id'){
            sessionStorage.setItem('historyScrollPosition', JSON.stringify({x: document.getElementsByClassName("table-div-2")[0].scrollLeft, y: document.getElementsByClassName("table-div-2")[0].scrollTop}));
            navigate(`/${userid}/history/${value}?${searchQP}`);
        }
        else if(path == 'home' || (!path && isUserID)){
            sessionStorage.removeItem('historyScrollPosition'); 
            navigate(`/?${searchQP}`);
        }
    };


    if(!searchParams.get('apikey') || !searchParams.get('developer_email') || !searchParams.get('app_id')){
        return ( 
            <div className="main-div">
                Please pass apikey, developer_email and app_id as paramters.
            </div>
         );
    }
    return ( 
        <div className="main-div">
            <div className="sidebar">
                <img src={require("./images/powercred_logo_3(1).png")} alt="powercred_logo" className='pwc-logo'/>
                <div className='profile-sidebar'>{searchParams.get('developer_email')}</div> 
                {/* <div className='item-sidebar item-sidebar-1'>Dashboard</div>   */}
                <div className={`item-sidebar ${!userid ? 'item-sidebar-selected' : ''}`} onClick={() => {userid && changeRoute('home');}}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill={!userid ? "#25ab9d" : 'black'} xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.66667 3.99996H15.3333C15.5101 3.99996 15.6797 3.92972 15.8047 3.8047C15.9298 3.67967 16 3.5101 16 3.33329C16 3.15648 15.9298 2.98691 15.8047 2.86189C15.6797 2.73686 15.5101 2.66663 15.3333 2.66663H4.66667C4.48986 2.66663 4.32029 2.73686 4.19526 2.86189C4.07024 2.98691 4 3.15648 4 3.33329C4 3.5101 4.07024 3.67967 4.19526 3.8047C4.32029 3.92972 4.48986 3.99996 4.66667 3.99996Z"/>
                        <path d="M15.3333 7.33337H4.66667C4.48986 7.33337 4.32029 7.40361 4.19526 7.52864C4.07024 7.65366 4 7.82323 4 8.00004C4 8.17685 4.07024 8.34642 4.19526 8.47144C4.32029 8.59647 4.48986 8.66671 4.66667 8.66671H15.3333C15.5101 8.66671 15.6797 8.59647 15.8047 8.47144C15.9298 8.34642 16 8.17685 16 8.00004C16 7.82323 15.9298 7.65366 15.8047 7.52864C15.6797 7.40361 15.5101 7.33337 15.3333 7.33337Z"/>
                        <path d="M15.3333 12H4.66667C4.48986 12 4.32029 12.0702 4.19526 12.1953C4.07024 12.3203 4 12.4899 4 12.6667C4 12.8435 4.07024 13.013 4.19526 13.1381C4.32029 13.2631 4.48986 13.3333 4.66667 13.3333H15.3333C15.5101 13.3333 15.6797 13.2631 15.8047 13.1381C15.9298 13.013 16 12.8435 16 12.6667C16 12.4899 15.9298 12.3203 15.8047 12.1953C15.6797 12.0702 15.5101 12 15.3333 12Z"/>
                        <path d="M1.33333 4.66667C2.06971 4.66667 2.66667 4.06971 2.66667 3.33333C2.66667 2.59695 2.06971 2 1.33333 2C0.596954 2 0 2.59695 0 3.33333C0 4.06971 0.596954 4.66667 1.33333 4.66667Z"/>
                        <path d="M1.33333 9.33329C2.06971 9.33329 2.66667 8.73634 2.66667 7.99996C2.66667 7.26358 2.06971 6.66663 1.33333 6.66663C0.596954 6.66663 0 7.26358 0 7.99996C0 8.73634 0.596954 9.33329 1.33333 9.33329Z"/>
                        <path d="M1.33333 14C2.06971 14 2.66667 13.4031 2.66667 12.6667C2.66667 11.9303 2.06971 11.3334 1.33333 11.3334C0.596954 11.3334 0 11.9303 0 12.6667C0 13.4031 0.596954 14 1.33333 14Z"/>
                    </svg>
                    User Listing
                </div>    
                {/* <div className='bottom-sidebar'>
                    <div className='bottom-sidebar-item bottom-sidebar-item-1'>Profile Settings</div>
                    <div className='bottom-sidebar-item'>Account Logout</div>
                </div> */}
            </div>
            {
                userid === undefined
                    ? ( 
                        <div className="table-div-2">
                            <ListAccount searchParams={searchParams} data={data} setData={setData} options={options} currentPage={currentPage} setCurrentPage={setCurrentPage} limit={limit} changeRoute={changeRoute} baseURL={baseURL} sortedColumn={sortedColumn} setSortedColumn={setSortedColumn}/>
                        </div>
                    ) : (
                        <div className="table-div-2">
                            <Outlet context={[searchParams, changeRoute, userid, isUserID, isHistory, isIDDetail, id, options, baseURL]}/>
                        </div>
                    )
            }
        </div>
     );
}

export default Backoffice;