/*
    Title: Lender's eKYC Dashboard

    Data presenting to the lender:
     - Name                | ✅ |
     - Nik                 | ✅ |
     - dob                 | ✅ |
     - KTP Image           | ✅ |
     - Selfie Image        | ✅ |
     - Gender              | ✅ |   
     - Citizenship         | ✅ |
     - Religion            | ✅ |
     - Marital Status      | ✅ |
     - Occupation          | ✅ |
     - Address             | ✅ |
     - Education           | ✅ |
     - Company Founded     | ✅ |
     - Number of Employees | ✅ |
     - Current Address     | ✅ |

    Services using: gopay.service, kyc.service
    Logic:
        - Get data from kyc.service                                                    | ✅ |
        - populate the data to the fields                                              | ✅ |
        - verify address from gopay.service                                            | ✅ |
        - if address is verified, show the number of addresses scanned                 | ✅ |
*/

import { useState, useEffect, useRef } from 'react';
import React from 'react';
import { Image as Imo } from 'primereact/image';
import { getKYC } from '../../services/kyc.service';
import { verifyAddress } from '../../services/gopay.service';
import { getDigitalFootPrint } from '../../services/telco.service';
import { getProducts } from '../../services/product.service'
import { Tooltip } from 'primereact/tooltip';
import FullPageLoader from "../FullPageLoader";
import alter from './alter.png';
import alterFail from './fail.png';
import './kyc.component.css'

const KYC = (id) => {
    const [getName, setName] = useState('');
    const [getNIK, setNIK] = useState('');
    const [getBirthDate, setBirthDate] = useState('');
    const [getGender, setGender] = useState('');
    const [getCitizenship, setCitizenship] = useState('');
    const [getReligion, setReligion] = useState('');
    const [getMaritalStatus, setMaritalStatus] = useState('');
    const [getOccupation, setOccupation] = useState('');
    const [getPlaceOfBirth, setPlaceOfBirth] = useState('');
    const [getEducation, setEducation] = useState('');
    const [getCompanyFounded, setCompanyFounded] = useState('');
    const [getNumberOfEmployees, setNumberOfEmployees] = useState('');
    const [getPhoneNumber, setPhoneNumber] = useState('');
    const [getDigitalFootPrintRef, setDigitalFootPrintRef] = useState(null);
    const [getEmail, setEmail] = useState('');
    const [getAddressScaned, setAddressScaned] = useState('');
    const [getMatchPercentage, setMatchPercentage] = useState('');  
    const [getAddressFoundIn, setAddressFoundIn] = useState('');
    const [isVerificationFetchFailed, setVerificationFetchFailed] = useState(false);
    const [isVerificationLoading, setVerificationLoading] = useState(false);
    const [getRejectedFields, setRejectedFields] = useState([]);
    const [isGoPayIncomplete, setGoPayIncomplete] = useState(false);
    const [gopayData, setGopayData] = useState(null);
    const [getAddress, setAddress] = useState('');
    const [getRtRw, setRtRw] = useState('');
    const [getSubDistrict, setSubDistrict] = useState('');
    const [getDistrict, setDistrict] = useState('');
    const [getCurrentAddress, setCurrentAddress] = useState('');
    const [getCurrentRtRw, setCurrentRtRw] = useState('');
    const [getCurrentSubDistrict, setCurrentSubDistrict] = useState('');
    const [getCurrentDistrict, setCurrentDistrict] = useState('');

    const [imageKTPData, setImageKTPData] = useState(null);
    const [imageSelfieData, setImageSelfieData] = useState(null);

    const [isIncomplete, setIncomplete] = useState(false);

    const [isDOBRejected, setDOBRejected] = useState(false);
    const [isNameRejected, setNameRejected] = useState(false);
    const [isNIKRejected, setNIKRejected] = useState(false);
    const [isGenderRejected, setGenderRejected] = useState(false);
    const [isCitizenshipRejected, setCitizenshipRejected] = useState(false);
    const [isReligionRejected, setReligionRejected] = useState(false);
    const [isMaritalStatusRejected, setMaritalStatusRejected] = useState(false);
    const [isOccupationRejected, setOccupationRejected] = useState(false);
    const [isAddressRejected, setAddressRejected] = useState(false);
    const [isPlaceOfBirthRejected, setPlaceOfBirthRejected] = useState(false);
    const [isEmailRejected, setEmailRejected] = useState(false);
    const [isPhoneNumberRejected, setPhoneNumberRejected] = useState(false);
    const [isSelfieImageRejected, setSelfieImageRejected] = useState(false);
    const [isEducationRejected, setEducationRejected] = useState(false);
    const [isCompanyFoundedRejected, setCompanyFoundedRejected] = useState(false);
    const [isNumberOfEmployeesRejected, setNumberOfEmployeesRejected] = useState(false);
    const [linkRef, setLinkRef] = useState(null);
    const [isDataReady, setIsDataReady] = useState(false);

    const [alternateSourceVerified, setAlternateSourceVerified] = useState(false);
    const [selfieMatchPercentage, setSelfieMatchPercentage] = useState('');
    const [negativeRecordStatus, setNegativeRecordStatus] = useState('');
    const [isAlternateVerificationFailed, setAlternateVerificationFailed] = useState(false);

    const [isGopayApplicable, setGopayApplicable] = useState(false);
    const [istelcoApplicable, setTelcoApplicable] = useState(false);

    useEffect(() => {
        const link = document.createElement('link');
        link.href = 'https://demos.creative-tim.com/argon-dashboard-pro/assets/css/argon-dashboard.min.css?v=2.0.5';
        link.rel = 'stylesheet';
        setLinkRef(link);
        if(!isDataReady || isIncomplete){
            getKYC(id.props).then((data) => {
                setName(data.user_input.name != null ? data.user_input.name : '');
                setNIK(data.user_input.nik != null ? data.user_input.nik : '');
                setPhoneNumber(data.user_input.phone_number != null ? data.user_input.phone_number : '');
                setEmail(data.user_input.email != null ? data.user_input.email : '');
                setBirthDate(data.user_input.birth_date != null ? data.user_input.birth_date : '');
                setGender(data.user_input.gender != null ? data.user_input.gender : '');
                setCitizenship(data.user_input.citizenship != null ? data.user_input.citizenship : '');
                setReligion(data.user_input.religion != null ? data.user_input.religion : '');
                setMaritalStatus(data.user_input.marital_status != null ? data.user_input.marital_status : '');
                setOccupation(data.user_input.occupation != null ? data.user_input.occupation : '');
                setPlaceOfBirth(data.user_input.place_of_birth != null ? data.user_input.place_of_birth : '');
                setEducation(data.user_input.education != null ? data.user_input.education : '');
                setCompanyFounded(data.user_input.company_founded_on != null ? data.user_input.company_founded_on : '');
                setNumberOfEmployees(data.user_input.employee_count != null ? data.user_input.employee_count : '');
                setAddress(data.user_input.address != null ? data.user_input.address.replace(/"/g, '') : '');
                setRtRw(data.user_input?.rtrw != null ? data.user_input?.rtrw : '');
                setSubDistrict(data.user_input?.sub_district != null ? data.user_input?.sub_district : '');
                setDistrict(data.user_input?.district != null ? data.user_input?.district : '');
                setCurrentAddress(data.user_input.current_address != null ? data.user_input.current_address != '' ? data.user_input.current_address.replace(/"/g, '') : '' : '');
                setCurrentRtRw(data.user_input?.current_rtrw != null ? data.user_input?.current_rtrw : '');
                setCurrentSubDistrict(data.user_input?.current_sub_district != null ? data.user_input?.current_sub_district : '');
                setCurrentDistrict(data.user_input?.current_district != null ? data.user_input?.current_district : '');
                setNegativeRecordStatus(data?.dukcapil_verification_result.negative_records?.status != null ? data.dukcapil_verification_result.negative_records?.status ? data?.dukcapil_verification_result?.negative_records?.status : '' : '');

                getProducts(id.props).then((data_product) => {
                    console.log(data_product);

                    if (data_product){
                        const products = data_product;

                        if(products.includes('identity-telco')){
                            setTelcoApplicable(true);
                            if (data.user_input.phone_number != null && data.dukcapil_verification_result != 'Incomplete dukcapil verification!') {
                                getDigitalFootPrint(id.props, data.user_input.phone_number).then((data) => {
                                    if (data?.code == "ERR_BAD_RESPONSE") {
                                        setDigitalFootPrintRef('');
                                    } else {
                                        setDigitalFootPrintRef(data);
                                    }
                                });
                            }
                        } else {
                            console.log("Telco not applicable")
                            setTelcoApplicable(false);
                        }

                        if(products.includes('identity-gopay')){
                            setGopayApplicable(true);
                            if (data.user_input.address != null && data.dukcapil_verification_result != 'Incomplete dukcapil verification!') {
                                const address = (data.user_input.current_address == '' || data.user_input.current_address == null) ? data.user_input.full_address : data.user_input.full_current_address;
                                console.log(address)
                                setVerificationLoading(true);
                                verifyAddress(id.props, address)
                                .then((data) => {
                                    if (data){
                                        setGopayData(data[0]);
                                        if(data[0].verified == false){
                                            setVerificationLoading(false);
                                            setAddressRejected(true);
                                        } else {
                                            setVerificationLoading(false);
                                            setAddressRejected(false);
                                        }
                                    } else {
                                        setVerificationLoading(false);
                                        setAddressRejected(true);
                                        setGoPayIncomplete(true);
                                    }
                                });
                            }
                        } else {
                            console.log("Gopay not applicable")
                            setGopayApplicable(false);
                        }
                    } else {
                        console.log("No products found")
                        setTelcoApplicable(false);
                        setGopayApplicable(false);
                    }
                });
    
                setNameRejected(data.user_input.name == null ? true : false)                
                setIncomplete(data.dukcapil_verification_result == 'Incomplete dukcapil verification!' ? true : false)
                setRejectedFields(data.dukcapil_verification_result == 'Incomplete dukcapil verification!' ? [] : data.dukcapil_verification_result?.reject_field);
                setDOBRejected(data.user_input.birth_date == null ? true : false || data.dukcapil_verification_result != 'Incomplete dukcapil verification!' ? data.dukcapil_verification_result?.reject_field.includes('birth_date') || data.dukcapil_verification_result?.reject_field.includes('birthdate') : false)
                setNIKRejected(data.user_input.nik == null ? true : false || data.dukcapil_verification_result != 'Incomplete dukcapil verification!' ? data.dukcapil_verification_result?.reject_field.includes('nik') : false)
                setGenderRejected(data.user_input.gender == null ? true : false || data.dukcapil_verification_result != 'Incomplete dukcapil verification!' ? data.dukcapil_verification_result?.reject_field.includes('gender') : false)
                setCitizenshipRejected(data.user_input.citizenship == null ? true : false || data.dukcapil_verification_result != 'Incomplete dukcapil verification!' ? data.dukcapil_verification_result?.reject_field.includes('citizenship') : false)
                setReligionRejected(data.user_input.religion == null ? true : false || data.dukcapil_verification_result != 'Incomplete dukcapil verification!' ? data.dukcapil_verification_result?.reject_field.includes('religion') : false)
                setMaritalStatusRejected(data.user_input.marital_status == null ? true : false || data.dukcapil_verification_result != 'Incomplete dukcapil verification!' ? data.dukcapil_verification_result?.reject_field.includes('marital_status') : false)
                setOccupationRejected(data.user_input.occupation == null ? true : false || data.dukcapil_verification_result != 'Incomplete dukcapil verification!' ? data.dukcapil_verification_result?.reject_field.includes('occupation') : false)
                setEmailRejected(data.user_input.email == null ? true : false || data.dukcapil_verification_result != 'Incomplete dukcapil verification!' ? data.dukcapil_verification_result?.reject_field.includes('email') : false)
                setPhoneNumberRejected(data.user_input.phone_number == null ? true : false || data.dukcapil_verification_result != 'Incomplete dukcapil verification!' ? data.dukcapil_verification_result?.reject_field.includes('phone') : false)
                setPlaceOfBirthRejected(data.user_input.place_of_birth == null ? true : false || data.dukcapil_verification_result != 'Incomplete dukcapil verification!' ? data.dukcapil_verification_result?.reject_field.includes('place_of_birth') || data.dukcapil_verification_result?.reject_field.includes('birthplace') : false)
                setEducationRejected(data.user_input.education == null ? true : false || data.dukcapil_verification_result != 'Incomplete dukcapil verification!' ? data.dukcapil_verification_result?.reject_field.includes('education') : false)
                setCompanyFoundedRejected(data.user_input.company_founded_on == null ? true : false || data.dukcapil_verification_result != 'Incomplete dukcapil verification!' ? data.dukcapil_verification_result?.reject_field.includes('company_founded_on') : false)
                setNumberOfEmployeesRejected(data.user_input.employee_count == null ? true : false || data.dukcapil_verification_result != 'Incomplete dukcapil verification!' ? data.dukcapil_verification_result?.reject_field.includes('employee_count') : false)
                setSelfieImageRejected(data.user_input.selfie_url == null ? true : false || data.dukcapil_verification_result != 'Incomplete dukcapil verification!' ? data.dukcapil_verification_result?.reject_field.includes('selfie_photo') : false)

                if (data.dukcapil_verification_result?.alternate_verification){
                    if(data.dukcapil_verification_result?.reject_field.includes('message')){
                        setAlternateVerificationFailed(true);
                        setAlternateSourceVerified(false);
                    }
                    else {
                        setSelfieMatchPercentage(data?.alternate_verification?.data?.selfie_photo);
                        setAlternateSourceVerified(true);
                    }
                }

                if (data.user_input.ktp_url) {
                    const img = new Image();
                    img.onload = () => {
                        setImageKTPData(img.src);
                    };
                    img.crossOrigin = 'anonymous';
                    img.src = data.user_input.ktp_url;
                }

                if (data.user_input.selfie_url) {
                    const img = new Image();
                    img.onload = () => {
                        setImageSelfieData(img.src);
                    };
                    img.crossOrigin = 'anonymous';
                    img.src = data.user_input.selfie_url;
                }


                setIsDataReady(true);
            });
        }
        return () => {
            document.head.removeChild(link);
        };
    }, []);

    return (
        <>
            {(() => {
                if (linkRef) {
                    document.head.appendChild(linkRef);
                }
            })()}

            {!(imageKTPData || imageSelfieData || getAddress) ? (<FullPageLoader />) :
                (
                    <>
                    <Tooltip target=".tt" mouseTrack mouseTrackLeft={10} />
                        <div className="container-fluid py-4">
                            <div className="row">
                                {(isIncomplete || isGoPayIncomplete || getDigitalFootPrintRef == '') && (
                                    <div style={{paddingBottom: '2rem', fontSize: '1rem'}}>
                                        <b>Connection Status: </b>
                                        {isIncomplete && (<span style={{ color: 'red'}}> Incomplete dukcapil verification!</span>)}
                                        {
                                            // TEMPORARILY COMMENTED FOR A PURPOSE !
                                            istelcoApplicable && isGopayApplicable && isGoPayIncomplete && getDigitalFootPrintRef == '' ? (
                                                <>
                                                <br />
                                                    1. <span style={{ color: 'red'}}> Unable to verify address, applicant failed to complete Gopay account connection.</span>  <br />
                                                    2. <span style={{ color: 'red'}}> Telco data is not available for the given number.</span>
                                                </>
                                            ) :
                                            (getDigitalFootPrintRef == '' && !isGoPayIncomplete && istelcoApplicable) ? (<span style={{ color: 'red'}}> Telco data is not available for the given number.</span>) :
                                            (isGoPayIncomplete && getDigitalFootPrintRef != '' && isGopayApplicable) ? (<span style={{ color: 'red'}}> Unable to verify address, applicant failed to complete Gopay account connection.</span>) :
                                            null
                                        }
                                    </div>
                                )}
                                <div className="col-lg-4 order-lg-2">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="">
                                                <div>
                                                    <h5 className="font-weight-bolder title">KTP Image</h5>
                                                    <div className="col-12">
                                                        <Imo className="w-100 border-radius-lg shadow-lg mt-3"
                                                            indicatorIcon={<i className="pi pi-search"></i>}
                                                            src={imageKTPData}
                                                            alt="Image"
                                                            preview
                                                        />
                                                    </div>
                                                </div>

                                                <div style={{ paddingTop: '2rem' }}>
                                                    <h5 className="font-weight-bolder title">Selfie Image {(isSelfieImageRejected ? <i className="pi pi-exclamation-triangle" style={{ color: 'red' }} /> : null) || 
                                                                                                           (getRejectedFields.includes('liveness_check') ? <i className="pi pi-exclamation-triangle" style={{ color: 'red' }} /> : null)}
                                                    {alternateSourceVerified && (<img src={alter} style={{height: '1.5rem'}} className='tt' data-pr-tooltip="Verified Through Alternate Souce"/>)}
                                                    {isAlternateVerificationFailed && (<img src={alterFail} style={{height: '1.5rem'}} className='tt' data-pr-tooltip="Data not found"/>)}
                                                    </h5>
                                                    <div className="col-12">
                                                        <Imo className="w-100 border-radius-lg shadow-lg mt-3"
                                                            indicatorIcon={<i className="pi pi-search"></i>}
                                                            src={imageSelfieData}
                                                            alt="Image"
                                                            preview
                                                            style={ (isSelfieImageRejected ? { border: '2px solid red' } : null) ||
                                                                    (getRejectedFields.includes('liveness_check') ? { border: '2px solid red' } : null)}
                                                        />
                                                    </div>
                                                    {alternateSourceVerified && (<h5 style={{paddingTop: '1rem' }}> Match Rate: {selfieMatchPercentage}%</h5>)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-8 order-lg-1 mt-lg-0 mt-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="font-weight-bolder superTitle">Borrower's Information</h5>
                                            <div className="row dataSection">
                                                <div className="col-12 col-sm-6">
                                                    <label className='littleTitle'>Name {
                                                    isNameRejected ? <i className="pi pi-exclamation-triangle" style={{ color: 'red' }} /> : null
                                                    } 
                                                    {alternateSourceVerified && (<img src={alter} style={{height: '1.5rem'}} className='tt' data-pr-tooltip="Verified Through Alternate Souce"/>)}
                                                    {isAlternateVerificationFailed && (<img src={alterFail} style={{height: '1.5rem'}} className='tt' data-pr-tooltip="Data not found"/>)}

                                                    </label>
                                                    <input disabled
                                                        style={isNameRejected ? { borderColor: 'red' } : null}
                                                        className="form-control"
                                                        value={getName}
                                                        type="text"
                                                    />
                                                </div>
                                                <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                                                    <label className='littleTitle'>NIK {isNIKRejected ? <i className="pi pi-exclamation-triangle" style={{ color: 'red' }} /> : null}
                                                    </label>
                                                    <input disabled
                                                        className="form-control"
                                                        type="text"
                                                        value={getNIK}
                                                        style={isNIKRejected ? { borderColor: 'red' } : null}
                                                    />
                                                </div>
                                                {/* <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                                                    <label className='littleTitle'>Mail {isEmailRejected ? <i className="pi pi-exclamation-triangle" style={{ color: 'red' }} /> : null}
                                                    </label>
                                                    <input disabled
                                                        className="form-control"
                                                        type="text"
                                                        value={getEmail}
                                                        style={isEmailRejected ? { borderColor: 'red' } : null}
                                                    />
                                                </div> */}
                                            </div>

                                            <div className="row dataSection">
                                                <div className="col-12 col-sm-6">
                                                    <label className='littleTitle'>Date of Birth {
                                                    isDOBRejected ? <i className="pi pi-exclamation-triangle" style={{ color: 'red' }} /> : null}
                                                    {alternateSourceVerified && (<img src={alter} style={{height: '1.5rem'}} className='tt' data-pr-tooltip="Verified Through Alternate Souce"/>)}
                                                    {isAlternateVerificationFailed && (<img src={alterFail} style={{height: '1.5rem'}} className='tt' data-pr-tooltip="Data not found"/>)}
                                                    </label>
                                                    <input disabled
                                                        className="form-control"
                                                        value={getBirthDate}
                                                        type="text"
                                                        style={isDOBRejected ? { borderColor: 'red' } : null}
                                                    />
                                                </div>
                                                <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                                                    <label className='littleTitle'>Place of Birth {
                                                    isPlaceOfBirthRejected ? <i className="pi pi-exclamation-triangle" style={{ color: 'red' }} /> : null}
                                                    {alternateSourceVerified && (<img src={alter} style={{height: '1.5rem'}} className='tt' data-pr-tooltip="Verified Through Alternate Souce"/>)}
                                                    {isAlternateVerificationFailed && (<img src={alterFail} style={{height: '1.5rem'}} className='tt' data-pr-tooltip="Data not found"/>)}
                                                    </label>
                                                    <input disabled
                                                        className="form-control"
                                                        type="text"
                                                        value={getPlaceOfBirth}
                                                        style={isPlaceOfBirthRejected ? { borderColor: 'red' } : null}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row dataSection">
                                                <div className="col-12 col-sm-6">
                                                    <label className='littleTitle'>Gender {isGenderRejected ? <i className="pi pi-exclamation-triangle" style={{ color: 'red' }} /> : null}
                                                    </label>
                                                    <input disabled
                                                        className="form-control"
                                                        value={getGender}
                                                        type="text"
                                                        style={isGenderRejected ? { borderColor: 'red' } : null}
                                                    />
                                                </div>
                                                <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                                                    <label className='littleTitle'>Citizenship {isCitizenshipRejected ? <i className="pi pi-exclamation-triangle" style={{ color: 'red' }} /> : null}
                                                    </label>
                                                    <input disabled
                                                        className="form-control"
                                                        type="text"
                                                        value={getCitizenship}
                                                        style={isCitizenshipRejected ? { borderColor: 'red' } : null}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row dataSection">
                                                <div className="col-12 col-sm-6">
                                                    <label className='littleTitle'>Religion {isReligionRejected ? <i className="pi pi-exclamation-triangle" style={{ color: 'red' }} /> : null}
                                                    </label>
                                                    <input disabled
                                                        className="form-control"
                                                        value={getReligion}
                                                        type="text"
                                                        style={isReligionRejected ? { borderColor: 'red' } : null}
                                                    />
                                                </div>
                                                <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                                                    <label className='littleTitle'>Marital Status {isMaritalStatusRejected ? <i className="pi pi-exclamation-triangle" style={{ color: 'red' }} /> : null}
                                                    </label>
                                                    <input disabled
                                                        className="form-control"
                                                        type="text"
                                                        value={getMaritalStatus}
                                                        style={isMaritalStatusRejected ? { borderColor: 'red' } : null}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row dataSection">
                                                <div className="col-12 col-sm-6">
                                                    <label className='littleTitle'>Occupation {isOccupationRejected ? <i className="pi pi-exclamation-triangle" style={{ color: 'red' }} /> : null}
                                                    </label>
                                                    <input disabled
                                                        className="form-control"
                                                        value={getOccupation}
                                                        type="text"
                                                        style={isOccupationRejected ? { borderColor: 'red' } : null}
                                                    />
                                                </div>
                                                <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                                                    <label className='littleTitle'>Education {isEducationRejected ? <i className="pi pi-exclamation-triangle" style={{ color: 'red' }} /> : null}
                                                    </label>
                                                    <input disabled
                                                        className="form-control"
                                                        type="text"
                                                        value={getEducation}
                                                        style={isEducationRejected ? { borderColor: 'red' } : null}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row dataSection">
                                                <div className="col-12 col-sm-6">
                                                    <label className='littleTitle'>Company Established on {isCompanyFoundedRejected ? <i className="pi pi-exclamation-triangle" style={{ color: 'red' }} /> : null}
                                                    </label>
                                                    <input disabled
                                                        className="form-control"
                                                        value={getCompanyFounded}
                                                        type="text"
                                                        style={isCompanyFoundedRejected ? { borderColor: 'red' } : null}
                                                    />
                                                </div>
                                                <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                                                    <label className='littleTitle'>Number of Employees {isNumberOfEmployeesRejected ? <i className="pi pi-exclamation-triangle" style={{ color: 'red' }} /> : null}
                                                    </label>
                                                    <input disabled
                                                        className="form-control"
                                                        type="text"
                                                        value={getNumberOfEmployees}
                                                        style={isNumberOfEmployeesRejected ? { borderColor: 'red' } : null}
                                                    />
                                                </div>
                                            </div>

                                            <hr style={{border: 'black solid 1px'}} />

                                            {getRtRw == '' || getRtRw == '-'  && (<>
                                            <div className="row dataSection">
                                                <div className="col-12">
                                                    <label className='littleTitle'>
                                                        {getCurrentAddress == '' ? 
                                                        (<span> Residential Address {getCurrentAddress == ''  && isVerificationLoading ? <i className="pi pi-spin pi-spinner" /> : <i className='pi pi-home' />} </span>) : 
                                                        (<span>KTP Address <i className='pi pi-id-card'></i> </span>)}
                                                        {getCurrentAddress == '' && isAddressRejected && isGopayApplicable && isGopayApplicable ? (<> (<span style={{ color: 'red'}}> Not Verified <i className="pi pi-exclamation-triangle" style={{ color: 'red', marginLeft: '3px' }} /> </span>)</>) : null}
                                                    </label>
                                                    <p className='address'>{
                                                        isIncomplete ? (getAddress.replace(/[\n]+/g, '').split('+').filter((item) => item !== 'None').join(', ')) : (
                                                            <>
                                                                {getAddress.replace(/\\n/g, ' ')}
                                                                {
                                                                    getCurrentAddress == '' ? (
                                                                        <>
                                                                        <br />
                                                                        { (gopayData?.verified) ? (<>
                                                                        {gopayData?.total_occurances ? (<>Found <b>{gopayData?.total_occurances}</b> times in </>) : null}
                                                                        <b>{gopayData?.total_addresses_scanned}</b> addresses scanned and is present in <b>{
                                                                        gopayData?.found_in == 'SAVED_LOCATIONS' ? 'Saved Location' : gopayData?.found_in == 'TRANSACTIONS' ? 'Transactions' : gopayData?.found_in
                                                                        }</b>.
                                                                        </>) : null }
                                                                        {(!gopayData?.verified) && (gopayData?.total_addresses_scanned) ? (<><b>{gopayData?.total_addresses_scanned}</b> Addresses Scaned.</>) : null}
                                                                        </>
                                                                    ) : null
                                                                }
                                                            </>
                                                        )
                                                    }</p>
                                                    <p>

                                                    </p>
                                                </div>
                                            </div>
                                            </>)}
                                            

                                            { getRtRw != '' && getRtRw != '-' && (<>
                                            <div className="row dataSection">
                                                <div className="col-12 col-sm-6">
                                                <label className='littleTitle'>
                                                        {getCurrentAddress == '' ? 
                                                        (<span> Residential Address {getCurrentAddress == ''  && isVerificationLoading ? <i className="pi pi-spin pi-spinner" /> : <i className='pi pi-home' />} </span>) : 
                                                        (<span>KTP Address <i className='pi pi-id-card'></i> </span>)}
                                                        {getCurrentAddress == '' && isAddressRejected && isGopayApplicable ? (<> (<span style={{ color: 'red'}}> Not Verified <i className="pi pi-exclamation-triangle" style={{ color: 'red', marginLeft: '3px' }} /> </span>)</>) : null}
                                                    </label>
                                                    <input disabled
                                                        className="form-control"
                                                        value={getAddress.replace(/\\n/g, ' ')}
                                                        type="text"
                                                        style={(getCurrentAddress == '' && isAddressRejected && isGopayApplicable) ? { borderColor: 'red' } : null}
                                                    />
                                                </div>

                                                <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                                                    <label className='littleTitle'>
                                                        {getCurrentAddress == '' ? 
                                                        (<span> RTRW {getCurrentAddress == ''  && isVerificationLoading ? <i className="pi pi-spin pi-spinner" /> : null} </span>) : 
                                                        (<span>KTP RTRW </span>)}
                                                        {(getCurrentAddress == '' && isAddressRejected && isGopayApplicable) ? <i className="pi pi-exclamation-triangle" style={{ color: 'red' }} /> : null}
                                                    </label>
                                                    <input disabled
                                                        className="form-control"
                                                        type="text"
                                                        value={getRtRw}
                                                        style={(getCurrentAddress == '' && isAddressRejected && isGopayApplicable) ? { borderColor: 'red' } : null}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row dataSection">
                                                <div className="col-12 col-sm-6">
                                                    <label className='littleTitle'>
                                                        {getCurrentAddress == '' ? 
                                                        (<span> Sub District {getCurrentAddress == ''  && isVerificationLoading ? <i className="pi pi-spin pi-spinner" /> : null} </span>) : 
                                                        (<span>KTP Sub District </span>)}
                                                         {(getCurrentAddress == '' && isAddressRejected && isGopayApplicable) ? <i className="pi pi-exclamation-triangle" style={{ color: 'red' }} /> : null}
                                                    </label>
                                                    <input disabled
                                                        className="form-control"
                                                        value={getSubDistrict}
                                                        type="text"
                                                        style={(getCurrentAddress == '' && isAddressRejected && isGopayApplicable) ? { borderColor: 'red' } : null}
                                                    />
                                                </div>
                                                <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                                                    <label className='littleTitle'>
                                                    {getCurrentAddress == '' ? 
                                                        (<span> District {getCurrentAddress == ''  && isVerificationLoading ? <i className="pi pi-spin pi-spinner" /> : null} </span>) : 
                                                        (<span>KTP District </span>)}
                                                        {(getCurrentAddress == '' && isAddressRejected && isGopayApplicable) ? <i className="pi pi-exclamation-triangle" style={{ color: 'red' }} /> : null}
                                                    </label>
                                                    <input disabled
                                                        className="form-control"
                                                        type="text"
                                                        value={getDistrict}
                                                        style={(getCurrentAddress == '' && isAddressRejected && isGopayApplicable) ? { borderColor: 'red' } : null}
                                                    />
                                                </div>
                                            </div>
                                            <p className='address'>{
                                                        (
                                                            <>
                                                            {getCurrentAddress == '' ? 
                                                                    (
                                                                        <>
                                                                        <br />
                                                                        { (gopayData?.verified) ? (<>
                                                                        {gopayData?.total_occurances ? (<>Found <b>{gopayData?.total_occurances}</b> times in </>) : null}
                                                                        <b>{gopayData?.total_addresses_scanned}</b> addresses scanned and is present in <b>{
                                                                        gopayData?.found_in == 'SAVED_LOCATIONS' ? 'Saved Location' : gopayData?.found_in == 'TRANSACTIONS' ? 'Transactions' : gopayData?.found_in
                                                                        }</b>.
                                                                        </>) : null }
                                                                        {(!gopayData?.verified) && (gopayData?.total_addresses_scanned) ? (<><b>{gopayData?.total_addresses_scanned}</b> Addresses Scaned.</>) : null}
                                                                        </>
                                                                    ) : null}
                                                            </>
                                                        )
                                            }</p>                                            
                                            </>)}

                                            
                                            {getCurrentAddress != '' && (getCurrentRtRw == '' || getCurrentRtRw == '-') &&(<>  
                                            <hr style={{border: 'black solid 1px'}} />
                                            <div className="row dataSection">
                                                <div className="col-12">
                                                    <label className='littleTitle'>
                                                        Residential Address {getCurrentAddress != ''  && isVerificationLoading ? <i className="pi pi-spin pi-spinner" /> : <i className='pi pi-home'/>}
                                                        {getCurrentAddress != '' && isAddressRejected && isGopayApplicable ? (<> (<span style={{ color: 'red'}}> Not Verified <i className="pi pi-exclamation-triangle" style={{ color: 'red', marginLeft: '3px' }} /> </span>)</>) : null}
                                                    </label>
                                                    <p className='address'>
                                                        {getCurrentAddress.replace(/\\n/g, ' ')}
                                                        <br />
                                                        { (gopayData?.verified) ? (<>
                                                        {gopayData?.total_occurances ? (<>Found <b>{gopayData?.total_occurances}</b> {gopayData?.total_occurances == '1' ? 'time' : 'times'} in </>) : null}
                                                        <b>{gopayData?.total_addresses_scanned}</b> addresses scanned and is present in <b>{
                                                        gopayData?.found_in == 'SAVED_LOCATIONS' ? 'Saved Location' : gopayData?.found_in == 'TRANSACTIONS' ? 'Transactions' : gopayData?.found_in
                                                        }</b>.
                                                        </>) : null }
                                                        {(!gopayData?.verified) && (gopayData?.total_addresses_scanned) ? (<><b>{gopayData?.total_addresses_scanned}</b> Address Scaned.</>) : null}
                                                    </p>
                                                </div>
                                            </div>
                                            </>)}

                                            {getCurrentAddress != '' && (getCurrentRtRw != '' && getCurrentRtRw != '-') && (<>
                                                <hr style={{border: 'black solid 1px'}} />
                                                <div className="row dataSection">
                                                    <div className="col-12 col-sm-6">
                                                        <label className='littleTitle'>
                                                            Residential Address {getCurrentAddress != ''  && isVerificationLoading ? <i className="pi pi-spin pi-spinner" /> : <i className='pi pi-home'/>}
                                                            {getCurrentAddress != '' && isAddressRejected && isGopayApplicable ? (<> (<span style={{ color: 'red'}}> Not Verified <i className="pi pi-exclamation-triangle" style={{ color: 'red', marginLeft: '3px' }} /> </span>)</>) : null}
                                                        </label>
                                                        <input disabled
                                                            className="form-control"
                                                            value={getCurrentAddress.replace(/\\n/g, ' ')}
                                                            type="text"
                                                            style={getCurrentAddress != '' && isAddressRejected && isGopayApplicable ? { borderColor: 'red' } : null}
                                                        />
                                                    </div>

                                                    <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                                                        <label className='littleTitle'>RTRW {isAddressRejected ? <i className="pi pi-exclamation-triangle" style={{ color: 'red' }} /> : null}
                                                        </label>
                                                        <input disabled
                                                            className="form-control"
                                                            type="text"
                                                            value={getCurrentRtRw}
                                                            style={getCurrentAddress != '' && isAddressRejected && isGopayApplicable ? { borderColor: 'red' } : null}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row dataSection">
                                                    <div className="col-12 col-sm-6">
                                                        <label className='littleTitle'>Sub District {isAddressRejected ? <i className="pi pi-exclamation-triangle" style={{ color: 'red' }} /> : null}
                                                        </label>
                                                        <input disabled
                                                            className="form-control"
                                                            value={getCurrentSubDistrict}
                                                            type="text"
                                                            style={getCurrentAddress != '' && isAddressRejected && isGopayApplicable ? { borderColor: 'red' } : null}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                                                        <label className='littleTitle'>District {isAddressRejected ? <i className="pi pi-exclamation-triangle" style={{ color: 'red' }} /> : null}
                                                        </label>
                                                        <input disabled
                                                            className="form-control"
                                                            type="text"
                                                            value={getCurrentDistrict}
                                                            style={getCurrentAddress != '' && isAddressRejected && isGopayApplicable ? { borderColor: 'red' } : null}
                                                        />
                                                    </div>
                                                </div>

                                                <p className='address'>
                                                    { (gopayData?.verified) ? (<>
                                                    {gopayData?.total_occurances ? (<>Found <b>{gopayData?.total_occurances}</b> {gopayData?.total_occurances == '1' ? 'time' : 'times'} in </>) : null}
                                                    <b>{gopayData?.total_addresses_scanned}</b> addresses scanned and is present in <b>{
                                                    gopayData?.found_in == 'SAVED_LOCATIONS' ? 'Saved Location' : gopayData?.found_in == 'TRANSACTIONS' ? 'Transactions' : gopayData?.found_in
                                                    }</b>.
                                                    </>) : null }
                                                    {(!gopayData?.verified) && (gopayData?.total_addresses_scanned) ? (<><b>{gopayData?.total_addresses_scanned}</b> Address Scaned.</>) : null}
                                                </p>
                                                
                                            </>)}

                                            

                                            {negativeRecordStatus != '' && (<><hr style={{border: 'black solid 1px'}} />
                                                <div className="row dataSection">
                                                <div className="col-12">
                                                    <label className='littleTitle'>
                                                        Negative Record {isVerificationLoading ? <i className="pi pi-spin pi-spinner" /> : null}
                                                    </label>
                                                    <p className='address'>{negativeRecordStatus}</p>
                                                </div>
                                            </div></>
                                            )}

                                        </div>
                                    </div>

                                    {getDigitalFootPrintRef != null && getDigitalFootPrintRef != '' && (
                                        <div className="mt-4">
                                            <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <h5 className="font-weight-bolder">Digital Footprint Summary</h5>


                                                    <div className="table-responsive">
                                                        <table className="table align-items-center mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                                        Category
                                                                    </th>
                                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                                        Information
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <div className="d-flex px-2 py-1">
                                                                            <div className="d-flex flex-column justify-content-center">
                                                                                <h6 className="mb-0 text-xs">Phone Number</h6>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex px-2 py-1">
                                                                            <div className="d-flex flex-column justify-content-center">
                                                                                <h6 className="mb-0 text-xs">+62 {getPhoneNumber}</h6>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div className="d-flex px-2 py-1">
                                                                            <div className="d-flex flex-column justify-content-center">
                                                                                <h6 className="mb-0 text-xs">Linked Messenger</h6>
                                                                            </div>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div className="d-flex px-2 py-1">
                                                                            <div className="d-flex flex-column justify-content-center">
                                                                                <h6 className="mb-0 text-xs">{getDigitalFootPrintRef.phone.registered_messaging == "" ? '-' : getDigitalFootPrintRef.phone.registered_messaging.split(',').map(item => item.trim()).join(', ')}</h6>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div className="d-flex px-2 py-1">
                                                                            <div className="d-flex flex-column justify-content-center">
                                                                                <h6 className="mb-0 text-xs">Linked Email</h6>
                                                                            </div>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div className="d-flex px-2 py-1">
                                                                            <div className="d-flex flex-column justify-content-center">
                                                                                <h6 className="mb-0 text-xs">{getDigitalFootPrintRef.phone.registered_email_provider == "" ? '-' : getDigitalFootPrintRef.phone.registered_email_provider}</h6>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div className="d-flex px-2 py-1">
                                                                            <div className="d-flex flex-column justify-content-center">
                                                                                <h6 className="mb-0 text-xs">Linked Social Media</h6>
                                                                            </div>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div className="d-flex px-2 py-1">
                                                                            <div className="d-flex flex-column justify-content-center">
                                                                                <h6 className="mb-0 text-xs">{getDigitalFootPrintRef.phone.registered_social_media == "" ? '-' : getDigitalFootPrintRef.phone.registered_social_media.split(',').map(item => item.trim()).join(', ')}</h6>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    )}
                                </div>



                            </div>


                        </div>
                    </>
                )
            }
        </>
    )
}
export default KYC;
