import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Backoffice from './components/Backoffice';
import AccountDetail from './components/AccountDetail';
import History from './components/History';
import PageNotFound from './components/PageNotFound';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={ <Backoffice /> }>
            <Route path=":userid" element={ <AccountDetail /> }>
              <Route path="history" element={ <History /> }>
                <Route path=":id"/>
              </Route>
            </Route>
          </Route>
          <Route path="*" element={ <PageNotFound /> } /> 
        </Routes>
      </div>
    </BrowserRouter>
  );
}
export default App;
