// import { Grid } from 'react-loader-spinner';

const FullPageLoader = () => {
  return (
    <div className="lds-container">
      <div className="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
    // <div className='fp-container'>
    //     <div className='fp-loader'>
    //         <Grid color="#1c1b4b" height={70} width={70} ariaLabel='loading' />
    //     </div>
    // </div>
  )
}

export default FullPageLoader