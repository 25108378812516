import { useEffect, useState } from 'react';
import './Modal.css';

const ErrorModal = (props) => {
    const { message, closeModal} = props;

    return ( 
        <div id="myModal" className="modal">
            <div className="modal-content error-modal-content">
                <span className="close" onClick={() => closeModal()}>&times;</span>
                <p>
                    { message ? message : 'Error in getting data'}
                </p>
            </div>
        </div>
     );
}
 
export default ErrorModal;