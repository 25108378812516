import { useEffect, useState } from 'react';
import './Modal.css';

const DeleteModal = (props) => {
    const { message, deletePdata, closeModal, isdeleted} = props;

      const handleClick = () => {
        closeModal();
      };
    
    console.log(isdeleted)
    return ( 
        <div id="myModal" className="modal" onClick={(e) => {return; if(e.target == document.getElementById("myModal")) closeModal(); }}>
            <div className="modal-content">
            <span className="close" onClick={handleClick}>&times;</span>
                {
                    !isdeleted && (
                message.isError
                    ? `Error: ${message.detail}`
                    : (
                    <>
                        {message.message1}
                        <div className={`export-csv-btn download-excel-modal`} onClick={() => deletePdata()}> 
                        Delete Data
                        </div>                 
                    </>
                    ) )
                }

                {
                    isdeleted && ( 
                        <div>{message.message2} </div>                                    
                    )
                }
               
            </div>
        </div>
     );
}
 
export default DeleteModal;