import axios from 'axios';

const isProduction = window.location.href.includes('https://dashboard.powercred.io') || window.location.href.includes('wqzd4eryoa');
const baseURL = isProduction ? 'https://dev.powercred.io' : 'https://mock.powercred.io';

export const getProducts = async (id) => {
    const apikey = new URLSearchParams(window.location.search).get('apikey');
    try{
        const response = await axios.post(`${baseURL}/auth/token/profile?apikey=${apikey}&id=${id}`);
        if (response){
            const products = response.data.api_product_list_json;
            return products;
        }
        else {
            return response;
        }
    } catch { 
        return false;
    }
}