/*

*/
import axios from 'axios';

const wantLocal = false; // Mandatory to set it false before pushing it to gitlab
export const getKYC = async (id) => {
    const isProduction = window.location.href.includes('https://dashboard.powercred.io') || window.location.href.includes('wqzd4eryoa');
    const baseURL = isProduction ? 'https://dev.powercred.io' : 'https://mock.powercred.io';
    const apikey = new URLSearchParams(window.location.search).get('apikey');
    const url = wantLocal ? `http://localhost:8000/get/data?apikey=${apikey}&id=${id}` : `${baseURL}/identity/get/data?apikey=${apikey}&id=${id}`;
    const response = await axios.get(url);
    return response.data;
}