import { useEffect, useState } from "react";
import axios from 'axios';

const BackofficeRowDetail = (props) => {
    const {data} = props;

    const [selectedPartner, setSelectedPartner] = useState();
    const [partnerResponse, setPartnerResponse] = useState({});

    const [selectedTab, setSelectedTab] = useState('Transactions');

    const getPartnerData = async (partner) => {
        const config = {
            method: 'GET',
            url: `https://dataservice-sog3xlfxxq-et.a.run.app/get/${partner}/${data.id}`,
        };
        try{
            let response = await axios(config);
            response = response.data;
            console.log(response);
            const partnerResponseNew = partnerResponse;
            partnerResponse[partner] = response;
            setPartnerResponse(partnerResponse);
        }
        catch(e){
            console.log('error in fetching backoffice partner data ', e);
        }
    };

    const tabsChanged =  value => {
        setSelectedTab(value);
    }


    useEffect(() => {
        if(data.connected_partners && data.connected_partners[0]){
            const partner = data.connected_partners[0];
            setSelectedPartner(partner);
            if(!partnerResponse[partner]){
                getPartnerData(partner);
            }
        }
    }, []);

    return ( 
        <div className="row-detail-div">
            <div className="row-detail-partner-container">
                {
                    data.connected_partners.map(partner => {
                        return (
                            <div className={`row-detail-partner ${selectedPartner ==  partner ? 'row-detail-partner-selected' : ''}`} key={partner} onClick={() => { setSelectedPartner(partner); if(!partnerResponse[partner]){ getPartnerData(partner); }}}>
                                { partner }
                            </div>
                        );
                    })
                }
            </div>
            <div className="box-container">
                <div className="box1">
                    <div className="box1-title1">Account Holder</div>
                    <div className="box1-body">
                        <div className="box1-i1">
                            <span className="box1-heading1">Name</span>
                            <span className="box1-detail1">PINGGAN EMAS PUSAKA PT</span>
                        </div>
                        <div className="box1-i1 box1-i2">
                            <span className="box1-heading1">Address</span>
                            <span className="box1-detail1">KOMPLEK DEPKES II E NO 5 PONDOKGEDE JATIBENING RT 002/006 BEKASI 17412</span>
                        </div>
                    </div>
                </div>
                <div className="box1">
                    <div className="box1-title1">Statement Details</div>
                    <div className="box1-body">
                        <div className="box1-i1">
                            <span className="box1-heading1">Bank</span>
                            <span className="box1-detail1">BCA CORPORATE</span>
                        </div>
                        <div className="box1-i1 box1-i2">
                            <span className="box1-heading1">Accounts</span>
                            <span className="box1-detail1">1</span>
                        </div>
                        <div className="box1-i1 box1-i2">
                            <span className="box1-heading1">Retrieved</span>
                            <span className="box1-detail1">03 Apr 2023 at 05:06:43 PM</span>
                        </div>
                        <div className="box1-i1 box1-i2">
                            <span className="box1-heading1">Updated</span>
                            <span className="box1-detail1">03 Apr 2023 at 05:06:43 PM</span>
                        </div>
                        <div className="box1-i1 box1-i2">
                            <span className="box1-heading1">External ID</span>
                            <span className="box1-detail1">ae2342891f764f06a364b0e40a99f067</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tabs1-outer">
                <div className="tabs1-container"> 
                    <div className={`tabs1-div ${selectedTab == 'Transactions' ? 'tabs1-selected' : ''}`} onClick={() => tabsChanged('Transactions')}>Transactions</div>
                    <div className={`tabs1-div ${selectedTab == 'Income' ? 'tabs1-selected' : ''}`} onClick={() => tabsChanged('Income')}>Income</div>
                    <div className={`tabs1-div ${selectedTab == 'Expense' ? 'tabs1-selected' : ''}`} onClick={() => tabsChanged('Expense')}>Expense</div>
                    <div className={`tabs1-div ${selectedTab == 'Risk' ? 'tabs1-selected' : ''}`} onClick={() => tabsChanged('Risk')}>Risk</div>
                </div>
            </div>
        </div>
     );
}
 
export default BackofficeRowDetail;