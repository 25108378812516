import { useState } from "react";
import axios from 'axios';
import ErrorModal from "./ErrorModal";

const Home = () => {
    const [formData, setFormData] = useState({apikey: '', developer_email: '', app_id: ''});

    const isProduction = window.location.href.includes('https://dashboard.powercred.io') || window.location.href.includes('wqzd4eryoa');
    const baseURL = isProduction ? 'https://dev.powercred.io' : 'https://mock.powercred.io';

    const [isLoading, setLoader] = useState(false);  

    const [data, setData] = useState([]);

    const limit = 25;

    const [showErrorModal, setShowErrorModal] = useState();

    const changeValue = (key, value) => {
        const newFormData = {...formData};
        newFormData[key] = value;
        setFormData(newFormData);
    };

    const submitForm = (e) => {
        e.preventDefault();
        getDataCount();
    };

    const getDataCount = async () => {
        setLoader(true);
        const config = {
            method: 'GET',
            baseURL,
            url: '/backoffice/users/count',
            params: {
                apikey: formData['apikey'],
                developer_email: formData['developer_email'], 
                app_id: formData['app_id'],
                limit
            },
            headers: { 
                'accept': 'application/json'
            }
        };
        try{
            let response = await axios(config);
            response = response.data;

            let data = response.id_list.map(id => {
                return { id };
            });
            data = [{}].concat(data);

            setData(data);
        }
        catch(e){
            console.log('error in fetching data count', e);
            setShowErrorModal({message: 'Error djdjd'})
        } 
        setLoader(false);
    };

    return ( 
        <div>
            Home
            <form onSubmit={submitForm}>
                <div>
                    <label>Api key</label>
                    <input
                        type="text"
                        id="apikey"
                        className='form-control'
                        placeholder="apikey"
                        name="apikey"
                        onChange={(e) => changeValue("apikey", e.target.value)}
                        value={formData.apikey}
                        required
                    />
                </div>
                <div>
                    <label>Developer Email</label>
                    <input
                        type="email"
                        id="developer_email"
                        className='form-control'
                        placeholder="developer_email"
                        name="developer_email"
                        onChange={(e) => changeValue("developer_email", e.target.value)}
                        value={formData.developer_email}
                        required
                    />
                </div>
                <div>
                    <label>App ID</label>
                    <input
                        type="text"
                        id="app_id"
                        className='form-control'
                        placeholder="app_id"
                        name="app_id"
                        onChange={(e) => changeValue("app_id", e.target.value)}
                        value={formData.app_id}
                        required
                    />
                </div>
                <button type="sumbit">Sumbit</button>
            </form>
            {
                showErrorModal && <ErrorModal message={showErrorModal.message} closeModal={() => {setShowErrorModal();}}/>
            }
        </div>
     );
}
 
export default Home;