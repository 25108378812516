import { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from './Modal';
import DeleteModal from './DeleteModal';
import FullPageLoader from "./FullPageLoader";
import KYC from './KYC/kyc.component';
import RefreshModal from './RefreshModal';

const PartnersDetail = (props) => {
    const {searchParams, id, isUserID, isIDDetail, eCommerceList, setECommerceList, download, setIsShowExcelDownload, baseURL, userIDAccountDetail, setUserIDAccountDetail, options, userIDData} = props;

    const categoriesList = [ 'Bank E-statement Upload', 'E-Wallet', 'Telco', 'E-Commerce', 'Accounting & POS', 'Social', 'Bank Verification', 'E-KYC'];
    
    const [categories, setCategories] = useState({});

    const [selectedCategory, setSelectedCategory] = useState();

    const [selectedPartner, setSelectedPartner] = useState();

    const [tables, setTables] = useState();

    const [selectedTable, setSelectedTable] = useState();

    const [rowsPerPage, setRowsPerPage] = useState(20);

    const [pageNo, setPageNo] = useState(1);

    const [isSummaryStmt, setIsSummaryStmt] = useState(false);

    const [dashboardStatus, setDashboardStatus] = useState();

    const [selectedPartnerInfoTable, setSelectedPartnerInfoTable] = useState(0);

    const [selectedId, setSelectedId] = useState(0);

    const [downloadingExcel, setDownloadingExcel] = useState();

    const [showModal, setShowModal] = useState();

    const [showDeleteModal, setShowDeleteModal] = useState();

    const [selectedLogsID, setSelectedLogsID] = useState();

    const [isLogsIDError, setIsLogsIDError] = useState();

    const [isAfterInit, setIsAfterInit] = useState();

    const [isDownloadStatement,setIsDownloadStatement] = useState(false);

    const[refreshModal, setRefreshModal] = useState();

    const [isLoading, setLoader] = useState(false);

    const [isdeleted, setIsDeleted] = useState(false);

    const changeCategory = category => {
        if(category == selectedCategory){
            return;
        }
        setSelectedCategory(category);
        
        const newCategories = categories;
        newCategories[selectedCategory].selectedPartner = selectedPartner;
        setCategories(newCategories);

        !id && setUserIDAccountDetail({...userIDAccountDetail, categories: newCategories, category});

        const newSelectedPartner = categories[category].selectedPartner ? categories[category].selectedPartner : categories[category].partners[0];
        changePartner(newSelectedPartner, eCommerceList[newSelectedPartner].selectedId ? eCommerceList[newSelectedPartner].selectedId : 0);
    };

    const changePartner = async (partnerIndex, idIndex, logsIDIndex) => {
        if(partnerIndex == selectedPartner && idIndex == selectedId){
            return;
        }

        setTables(undefined);
        setSelectedTable(undefined);
        setSelectedPartnerInfoTable(0);
        setPageNo(1);
        
        if(isIDDetail){
            if(selectedLogsID !== undefined){
                logsIDIndex = selectedLogsID;
            }
            const data = eCommerceList[partnerIndex].data.find(idData => idData.id == userIDData[logsIDIndex].id);
            idIndex = eCommerceList[partnerIndex].data.indexOf(data);
            setSelectedLogsID(logsIDIndex);
        }

        let file;
        if(!eCommerceList[partnerIndex].data[idIndex].isError || (eCommerceList[partnerIndex].data[idIndex].isError && eCommerceList[partnerIndex].isCheckExcel)){ 
            if(eCommerceList[partnerIndex].data[idIndex].isExcelLinkFetched){
                file = eCommerceList[partnerIndex].data[idIndex].excelLink;
            }
            else{
                setLoader(true);
                file = await getExcelLink(partnerIndex, idIndex);
                setLoader(false);
            }
        }
        
        if(file && !eCommerceList[partnerIndex].isShowOnlyDownload && !eCommerceList[partnerIndex].data[idIndex].isError){
            getPartnerData(partnerIndex, idIndex);
        }

        if(file && eCommerceList[partnerIndex].data[idIndex].isError){
            setECommerceList(eCommerceList => {
                eCommerceList[partnerIndex].data[idIndex].isError = false;
                return eCommerceList;
            });
        }

        setSelectedPartner(partnerIndex);
        setSelectedId(idIndex);
        setIsShowExcelDownload(file);
        !id && setUserIDAccountDetail(userIDAccountDetail => {return {...userIDAccountDetail, selectedPartner: partnerIndex};});
    };

    const changeTable = table => {
        if(table == selectedTable){
            return;
        }
        setSelectedTable(table);
        
        eCommerceList[selectedPartner].data[selectedId].selectedTable = table;
        setECommerceList(eCommerceList);
        
        setPageNo(eCommerceList[selectedPartner].data[selectedId].data[table].pageNo ? eCommerceList[selectedPartner].data[selectedId].data[table].pageNo : 1);
    };

    const changePartnerInfoTable = table => {
        if(table == selectedPartnerInfoTable){
            return;
        }
        setSelectedPartnerInfoTable(table);

        eCommerceList[selectedPartner].data[selectedId].selectedPartnerInfoTable = table;
        setECommerceList(eCommerceList);
    };

    const changeId = id => {
        if(id == selectedId){
            return;
        }
        setSelectedId(id);
        
        eCommerceList[selectedPartner].selectedId = id;
        setECommerceList(eCommerceList);

        changePartner(selectedPartner, id);
    };

    const changePage = pageNo => {
        const newECommerceList = eCommerceList;
        newECommerceList[selectedPartner].data[selectedId].data[selectedTable].pageNo = pageNo;
        setECommerceList(newECommerceList);

        setPageNo(pageNo);
    };

    const getPartnerData = async (partnerIndex, idIndex) => {
        if(eCommerceList[partnerIndex].data[idIndex].data){
            const table = eCommerceList[partnerIndex].data[idIndex].selectedTable ? eCommerceList[partnerIndex].data[idIndex].selectedTable : Object.keys(eCommerceList[partnerIndex].data[idIndex].data).find(key => !eCommerceList[partnerIndex].formatting[key] || (eCommerceList[partnerIndex].formatting[key] && !eCommerceList[partnerIndex].formatting[key].isHidden));
            setTables(eCommerceList[partnerIndex].data[idIndex].data); //
            setSelectedTable(table);
            setPageNo(table && eCommerceList[partnerIndex].data[idIndex].data[table].pageNo ? eCommerceList[partnerIndex].data[idIndex].data[table].pageNo : 1);
            setSelectedPartnerInfoTable(eCommerceList[partnerIndex].data[idIndex].selectedPartnerInfoTable ? eCommerceList[partnerIndex].data[idIndex].selectedPartnerInfoTable : 0);
            return;
        }
        setLoader(true);
        try{
            const config = {
                method: 'GET',
                baseURL,
                url: `/backoffice/data/${eCommerceList[partnerIndex].code}/${eCommerceList[partnerIndex].data[idIndex].id}`,
                params: {
                    apikey: searchParams.get('apikey'),
                    developer_email: searchParams.get('developer_email'), 
                    app_id: searchParams.get('app_id')
                },
                headers: { 
                    'accept': 'application/json'
                }
            };
            let response = await axios(config);
            response = response.data;
            
            let data = response[eCommerceList[partnerIndex].dataKey];
            const tables = {};

            Object.keys(data).forEach(key => {
                if(typeof data[key] === 'object' && data[key] !== null){
                    if(eCommerceList[partnerIndex].formatting[key] && eCommerceList[partnerIndex].formatting[key].transformTable){
                        tables[key] = eCommerceList[partnerIndex].formatting[key].transformTable(data[key]);
                    }
                    else{
                        tables[key] = transformTable(data[key]);
                    }
                }
                else{
                    tables[key] = { headers: {}, rows: [] };
                }
            });
            
            setECommerceList(eCommerceList => {
                const newEcommerceList = [...eCommerceList];
                newEcommerceList[partnerIndex] = {...newEcommerceList[partnerIndex]};
                newEcommerceList[partnerIndex].data[idIndex].data = tables;
                if(newEcommerceList[partnerIndex].isPartnerInfoShowing){
                    const partnerInfo = newEcommerceList[partnerIndex].getPartnerInfo(tables);
                    if(partnerInfo.length){
                        newEcommerceList[partnerIndex].data[idIndex].partnerInfo = partnerInfo;
                    }
                }
                return newEcommerceList;
            });
            setTables(tables);
            setSelectedTable(Object.keys(tables).find(key => !eCommerceList[partnerIndex].formatting[key] || (eCommerceList[partnerIndex].formatting[key] && !eCommerceList[partnerIndex].formatting[key].isHidden)));
            setPageNo(1);
        }
        catch(e){
            console.log(`error in fetching ${eCommerceList[partnerIndex].code} ${eCommerceList[partnerIndex].data[idIndex].id}`, e);
            setTables(undefined);
            setSelectedTable(undefined);
        } 
        setLoader(false);
    };

    const getExcelLinkConfig = async (partnerIndex, idIndex) => {
        try{
            console.log(eCommerceList[partnerIndex].codeAgg)
            const config = {
                method: 'GET',
                baseURL,
                url: `/backoffice/excel/${eCommerceList[partnerIndex].codeAgg}/${eCommerceList[partnerIndex].data[idIndex].id}`,
                params: {
                    apikey: searchParams.get('apikey'),
                    developer_email: searchParams.get('developer_email'), 
                    app_id: searchParams.get('app_id')
                },
                headers: { 
                    'accept': 'application/json'
                }
            };
            let response = await axios(config);
            response = response.data.statement_url;
            return response;
        }
        catch(e){
            console.log(`error in fetching excel link summary of ${eCommerceList[partnerIndex].codeAgg} ${eCommerceList[partnerIndex].data[idIndex].id}`, e);
        } 
    }

    const getExcelLink = async (partnerIndex, idIndex) => {
        setDownloadingExcel(true);
        try{
            const config = {
                method: 'GET',
                baseURL,
                url: `/backoffice/excel/${eCommerceList[partnerIndex].code}/${eCommerceList[partnerIndex].data[idIndex].id}`,
                params: {
                    apikey: searchParams.get('apikey'),
                    developer_email: searchParams.get('developer_email'), 
                    app_id: searchParams.get('app_id')
                },
                headers: { 
                    'accept': 'application/json'
                }
            };
            let response = await axios(config);
            response = response.data.statement_url;
            
            const newEcommerceList = [...eCommerceList];
            newEcommerceList[partnerIndex] = {...newEcommerceList[partnerIndex]};
            newEcommerceList[partnerIndex].data[idIndex].excelLink = response;
            newEcommerceList[partnerIndex].data[idIndex].isExcelLinkFetched = true;
            setECommerceList(newEcommerceList);
            setDownloadingExcel(false);
            return response;
        }
        catch(e){
            console.log(`error in fetching excel link of ${eCommerceList[partnerIndex].code} ${eCommerceList[partnerIndex].data[idIndex].id}`, e);
            setDownloadingExcel(false);
        } 
    };
    const getExcelSignedUrl = async (partnerIndex, idIndex) => {
        setDownloadingExcel(true);
        try{
            let response = await axios(eCommerceList[partnerIndex].summarygetExcelConfig(selectedId));
            response = response.data;
            setDownloadingExcel(false);
            return response;
        }
        catch(e){
            console.log(`error in fetching excel link of ${eCommerceList[partnerIndex].code} ${eCommerceList[partnerIndex].data[idIndex].id}`, e);
            setDownloadingExcel(false);
        } 
    };

    const createRows = (obj, headers) => {
        let rows = [];
        const row = {};
        Object.keys(obj).forEach(key => {
            if(Array.isArray(obj[key])){
                if(obj[key].length >= 1 && (typeof obj[key][0] == 'string' || obj[key][0] === null || typeof obj[key][0] !== 'object')){
                    if(!(key in headers)){
                        headers[key] = null;
                    }
                    if(rows.length){
                        rows.forEach(row => row[key] = JSON.stringify(obj[key]));
                    }
                    else{
                        row[key] = JSON.stringify(obj[key]);
                    }
                }
                else{
                    const arrayRows = [];
                    obj[key].forEach(innerObj => {
                        const innerRow = {};
                        Object.keys(innerObj).forEach(innerKey => {
                            if(Array.isArray(innerObj[innerKey])){
                                if(!(`${key}.${innerKey}` in headers)){
                                    headers[`${key}.${innerKey}`] = null;
                                }
                                innerRow[`${key}.${innerKey}`] = JSON.stringify(innerObj[innerKey]);
                            }
                            else if(typeof innerObj[innerKey] === 'object' && innerObj[innerKey] !== null){
                                Object.keys(innerObj[innerKey]).forEach(innerIKey => {
                                    if(!(`${key}.${innerKey}.${innerIKey}` in headers)){
                                        headers[`${key}.${innerKey}.${innerIKey}`] = null;
                                    }
                                    innerRow[`${key}.${innerKey}.${innerIKey}`] = JSON.stringify(innerObj[innerKey][innerIKey]); // value should be string
                                });
                            }
                            else{
                                if(!(`${key}.${innerKey}` in headers)){
                                    headers[`${key}.${innerKey}`] = null;
                                }
                                innerRow[`${key}.${innerKey}`] = innerObj[innerKey];
                            }
                        });
                        arrayRows.push(innerRow);
                    })
                    if(!rows.length){
                        rows.push(row);
                    }
                    rows = rows.reduce((prev, row) => {
                        arrayRows.forEach(arayRow => {
                            prev.push({...row, ...arayRow});
                        });
                        return prev;
                    }, []);
                }
            }
            else if(typeof obj[key] === 'object' && obj[key] !== null){
                Object.keys(obj[key]).forEach(innerKey => {
                    if(!(`${key}.${innerKey}` in headers)){
                        headers[`${key}.${innerKey}`] = null;
                    }
                    if(rows.length){
                        rows.forEach(row => row[`${key}.${innerKey}`] = JSON.stringify(obj[key][innerKey]));
                    }
                    else{
                        row[`${key}.${innerKey}`] = JSON.stringify(obj[key][innerKey]); // value should be string
                    }
                });
            }
            else{
                if(!(key in headers)){
                    headers[key] = null;
                }
                if(rows.length){
                    rows.forEach(row => row[key] = obj[key]);
                }
                else{
                    row[key] = obj[key];
                }
            }
        });
        !rows.length && rows.push(row);
        return rows;
    };

    const transformTable = (table) => {
        const headers = {};
        let rows = [];
        if(Array.isArray(table)){
            table.forEach(obj => {
                rows = rows.concat(createRows(obj, headers));
            })
        }
        else{
            rows = rows.concat(createRows(table, headers));
        }
        return { headers, rows };
    }

    const downloadExcel = async () => {
        if(isDownloadStatement === true){
            setLoader(true)
            const file = await getExcelSignedUrl(selectedPartner, selectedId);
            console.log(file.excel_file)
            download(file.excel_file);        }
        else if(eCommerceList[selectedPartner].data[selectedId].excelLink && isSummaryStmt === false){
            setLoader(true)
            download(eCommerceList[selectedPartner].data[selectedId].excelLink);
        }
        else if(isSummaryStmt === true){
            setLoader(true)
            const file = await getExcelLinkConfig(selectedPartner, selectedId); 
            download(file);
        }else{
            setLoader(true)
            const file = await getExcelLink(selectedPartner, selectedId); 
            download(file);
        }
        setLoader(false)
    };

    const deletePdata = async () =>{
        try{
            let response = await axios(eCommerceList[selectedPartner].deleteDataConfig(selectedId));
            response = response.data;
            console.log(response)
            setIsDeleted(true);
            window.location.reload();
        }
        catch(e){
            console.log(`error in deleteing ${eCommerceList[selectedPartner].code}`, e);
        }
    }

    const downloadPDF = async () => {
        try{
            setDashboardStatus('downloadingPDF');

            // let response = await axios(eCommerceList[selectedPartner].downloadPDFConfig);
            // response = response.data;
            // console.log(response);
            // const blobFile = new Blob([response?.data], {type: "application/octet-stream"});
            // const url = window.URL.createObjectURL(blobFile);
            // download(url, 'pdfs.zip');
            setLoader(true)
            download(`${baseURL}/backoffice/document/${eCommerceList[selectedPartner].data[selectedId].id}?apikey=${searchParams.get('apikey')}&developer_email=${searchParams.get('developer_email')}&app_id=${searchParams.get('app_id')}`);
            setLoader(false)
            setDashboardStatus();
        }
        catch(e){
            console.log(`error in fetching downloading PDF of ${eCommerceList[selectedPartner].code}`, e);
            setDashboardStatus();
        }
    };
    
    const initAllConnectedPartners = () => {
        let categories;
        let category;
        let selectedPartner;

        if(userIDAccountDetail){
            categories = userIDAccountDetail.categories;
            category = userIDAccountDetail.category;
            selectedPartner = userIDAccountDetail.selectedPartner;
        }
        else{
            categories = eCommerceList.filter(eCommerce => eCommerce.isShowing && !eCommerce.isLogsID).reduce((prev, eCommerce) => {
                if(eCommerce.category in prev){
                    prev[eCommerce.category].partners.push(eCommerceList.indexOf(eCommerce));
                }
                else{
                    prev[eCommerce.category] = {
                        isShowing: false,
                        partners: [eCommerceList.indexOf(eCommerce)]
                    };
                }
                return prev;
            }, {});
            category = categoriesList.find(category => category in categories);
            selectedPartner = category ? categories[category].partners[0] : undefined;
            setUserIDAccountDetail({categories, category, selectedPartner});
        }
        setCategories(categories);
        setSelectedCategory(category);
        
        if(selectedPartner !== undefined){
            changePartner(selectedPartner, eCommerceList[selectedPartner].selectedId ? eCommerceList[selectedPartner].selectedId : 0);
        }
    };

    const initIDPartners = () => {
        const idData = userIDData.find(idData => idData.id == id);
        if(!idData){
            setIsLogsIDError(true);
            return;
        }
        const index = userIDData.indexOf(idData);

        const categories = {};
        userIDData[index].connected_partners.forEach(partner => {
            let connectedPartner = partner;
            let connectionName;
            let errorMessage;
            let fileName;
            if(Array.isArray(partner)){
                connectedPartner = partner[0];
                connectionName = partner[1];
                errorMessage = partner[2];
                if(partner[2] == 'File uploaded successfully'){
                    const array = partner[3].split('/');
                    fileName = array[array.length - 1];
                }
            }
            const eCommerce = eCommerceList.find(eCommerce => eCommerce.code === connectedPartner);
            if(eCommerce != undefined && !eCommerce.data.find(idData => idData.id == userIDData[index].id)){
                eCommerce.data.push({
                    id: userIDData[index].id,
                    data: null,
                    errorMessage,
                    fileName,
                    connectionName,
                    created_at: userIDData[index].created_at,
                    isLogsID: true
                });
            }
            if(eCommerce != undefined){
                if(eCommerce.category in categories){
                    categories[eCommerce.category].partners.push(eCommerceList.indexOf(eCommerce));
                }
                else{
                    categories[eCommerce.category] = {
                        isShowing: false,
                        partners: [eCommerceList.indexOf(eCommerce)]
                    };
                }
            }
        });

        userIDData[index].incomplete_partners.forEach(partner => {
            let connectedPartner = partner;
            let connectionName;
            let errorMessage;
            if(Array.isArray(partner)){
                connectedPartner = partner[0];
                // connectionName = partner[1];
                errorMessage = partner[1];
            }
            const eCommerce = eCommerceList.find(eCommerce => eCommerce.code === connectedPartner);
            if(eCommerce != undefined && !eCommerce.data.find(idData => idData.id == userIDData[index].id)){
                eCommerce.isShowing = true;
                eCommerce.data.push({
                    id: userIDData[index].id,
                    data: null,
                    errorMessage: eCommerce.getErrorMessage ? eCommerce.getErrorMessage(errorMessage) : 'Authorization Incomplete',
                    connectionName,
                    created_at: userIDData[index].created_at,
                    isLogsID: true,
                    isError: true
                });

            }
            if(eCommerce != undefined){
                if(eCommerce.category in categories){
                    categories[eCommerce.category].partners.push(eCommerceList.indexOf(eCommerce));
                }
                else{
                    categories[eCommerce.category] = {
                        isShowing: false,
                        partners: [eCommerceList.indexOf(eCommerce)]
                    };
                }
            }
        })

        if(!Object.keys(categories).length){
            return;
        }
        
        setECommerceList(eCommerceList);
        setCategories(categories);
        setSelectedLogsID(index);
        
        const category = categoriesList.find(category => category in categories);
        setSelectedCategory(category);
        categories[category].partners.sort((a, b) => {
            if(eCommerceList[a].code === 'multi_bank_ocr'){
                return -1;
            }
            else if(eCommerceList[b].code === 'multi_bank_ocr'){
                return 1;
            }
            else{
                return 0;
            }
        });
        category && changePartner(categories[category].partners[0], undefined, index);
    };

    const refreshData = async (value) => {
        try{
            if (value === 'fclick') {
                setRefreshModal({
                    message: {
                        message1: (
                            <>
                                {/* Are you sure you want to refresh the data!<br />
                                <div style={{color:'#e61313',fontSize:'14px',paddingTop:'1em'}}>Note: Data will fetch from last refresh click to current click</div> */}
                                Note:<br /> Kindly download the Excel before clicking the Refresh Button once the button is clicked old data will no longer exist in Excel. Only the refreshed data will be available to download.

                            </>
                        )
                    }
                });
                value = '';
            }
            
            else{
                setRefreshModal()
                setDashboardStatus('requesting-refresh');
            setLoader(true)
            let response = await axios(eCommerceList[selectedPartner].refreshDataConfig(selectedId));
            response = response.data;

            if(response.status_code == 202){
                setShowModal({message: {message1: 'Refreshing the Data', message2: 'Data refreshed'}, timeOut: eCommerceList[selectedPartner].timeOutRefresh});
            }
            else{
                setShowModal({message: {isError: true, detail: 'unknown error'}});
                console.log('Error in refreshing data');
            }

            setDashboardStatus();
            }
        }
        catch(e){
            setShowModal({message: {isError: true, detail: e.response && e.response.data && e.response.data.detail ? e.response.data.detail : 'unknown error'}}); 
            console.log(`error in fetching refresh data of ${eCommerceList[selectedPartner].code}`, e);
            setLoader(false)
            setDashboardStatus();         
        }
        setLoader(false)
    };

    const summaryData = async () => {
        try{
            setDashboardStatus('requesting-summary');
            setLoader(true)
            let response = await axios(eCommerceList[selectedPartner].summaryDataConfig(selectedId));
            response = response.data;
            setIsSummaryStmt(true);
            
            if(response.status_code == 404 || response.error){
                setShowModal({message: {isError: true, detail: 'Data not found'}});
                console.log('Error in summary');
            }
            else{
                setShowModal({message: {message1: 'Generating the Summary', message2: 'Summary Generated'}, timeOut: eCommerceList[selectedPartner].timeOutSummary});
            }

            setDashboardStatus();
        }
        catch(e){
            setShowModal({message: {isError: true, detail: 'unknown error'}}); 
            console.log(`error in fetching summary data of ${eCommerceList[selectedPartner].code}`, e);
            setDashboardStatus();
            setLoader(false)
        }
        setLoader(false)
    };

    const deleteData = async () => {
        try{
            setDashboardStatus('requesting-data-delete');
            setShowDeleteModal({message: {message1:'Are you sure you want to delete the Partners data !!',message2: 'Data has been deleted !!'}});               
            setDashboardStatus();
        }
        catch(e){
            setShowModal({message: {isError: true, detail: 'unknown error'}}); 
            console.log(`error in delete data of ${eCommerceList[selectedPartner].code}`, e);
            setDashboardStatus();
        }
    };

    const downloadExcelData = async () => {
        try{
            setDashboardStatus('requesting-summary');
            setLoader(true)
            let response = await axios(eCommerceList[selectedPartner].summaryExcelConfig(selectedId));
            response = response.data;
            if(response.detail == ""){
                setShowModal({message: {isError: true, detail: 'Data not found'}});
                console.log('Error in summary');
            }
            else{
                // setIsDownloadStatement(true)
                // setShowModal({message: {message1: 'Generating the Statement', message2: 'Statement Generated'}, timeOut: eCommerceList[selectedPartner].timeOutStatement});     
                // setTimeout(async ()=>{    
                // const urlResponse = await getExcelSignedUrl(selectedPartner, selectedId);
                // console.log(urlResponse.excel_file)      
                // let responseModule = await axios(eCommerceList[selectedPartner].moduleExcelConfig(selectedId)); 
               
                // },30000)      
                    setIsDownloadStatement(true);
                    setShowModal({
                        message: {
                            message1: 'Generating the Statement',
                            message2: 'Statement Generated'
                        },
                        timeOut: eCommerceList[selectedPartner].timeOutStatement
                    });
                    
                    const initialTimeout = 45000; // Initial timeout value
                    const extendedTimeout = 55000; // Extended timeout value
    
                    setTimeout(async () => {
                    const urlResponsePromise = getExcelSignedUrl(selectedPartner, selectedId);
                    console.log(urlResponsePromise)
                    const urlResponse = await Promise.race([
                        urlResponsePromise,
                        new Promise(resolve => setTimeout(resolve, 1000, { timeoutReached: true }))
                    ]);
                    if (urlResponse.message) {
                        // Timeout reached, extend the timeout
                        setTimeout(async () => {
                        const urlResponseExtended = await urlResponsePromise;
                        let responseModule = await axios(eCommerceList[selectedPartner].moduleExcelConfig(selectedId));
                        console.log(responseModule);
                        setShowModal({
                            message: {
                                message1: 'Generating the Statement',
                                message2: 'Statement Generated'
                            },
                            timeOut: 'statement_generated'
                        });
                        }, extendedTimeout - initialTimeout);
                    } else {
                        // urlResponse received within the initial timeout
                        let responseModule = await axios(eCommerceList[selectedPartner].moduleExcelConfig(selectedId));
                        setShowModal({
                            message: {
                                message1: 'Generating the Statement',
                                message2: 'Statement Generated'
                            },
                            timeOut: 'statement_generated'
                        });
                    }
                    }, 1000);
                }
            setLoader(false)
            setDashboardStatus();
        }
        catch(e){
            setLoader(false)
            setShowModal({message: {isError: true, detail: 'Please wait for multi bank analysis to get completed! If the issue still exists after sometime, please contact our support.'}}); 
            console.log(`error in fetching analysis data of ${eCommerceList[selectedPartner].code}`, e);
            setDashboardStatus();
        }
    };



    useEffect(() => {
        if(isIDDetail){
            initIDPartners();
        }
        else{
            initAllConnectedPartners();
        }
        setIsAfterInit(true);
    },[id]);

    if(!isAfterInit){
        return <div></div>;
    }
    if(isIDDetail && isLogsIDError){
        return (
            <div className='error-div-1'>Error: ID is incorrect, Please check URL</div>
        );
    }
    return ( 
        <div>
            {
                Object.keys(categories).length 
                ? (
                    <div className="tab-category">
                        {
                            categoriesList.filter(category => category in categories).map((category) => {
                                return (
                                    <div key={category} className={`tab-category-item ${category == selectedCategory ? 'tab-category-item-selected' : ''}`} onClick={() => changeCategory(category)}>
                                        {category}
                                    </div>
                                );
                            })
                        }
                    </div>
                )
                : (
                    <div className='review-heading-1'>
                        {
                            isIDDetail ? 'No connection' : 'No partners are connected, Please click on "History" to see logs'
                        }
                    </div>
                )
            }
            <div className="partners-container">
                {
                    selectedCategory in categories && selectedCategory == "E-KYC" && categories[selectedCategory].partners.map((partnerIndex) => {
                        const ekyc = eCommerceList[partnerIndex];
                        const historyID = id;
                        const currentID = ekyc.data.find(item => item.id === id);
                        const lastestID = ekyc.data[0].id;

                        if(currentID?.errorMessage === 'Authorization Incomplete'){
                            return (null)
                        } else {
                            return (
                                <KYC props={historyID ? historyID : lastestID} key={ekyc.code}/>
                            )
                        }
                    })
                }

                {
                    selectedCategory in categories && selectedCategory !== "E-KYC" && 
                    categories[selectedCategory].partners
                        .sort((a, b) => {
                            if(eCommerceList[a].code === 'multi_bank_ocr'){
                                return -1;
                            }
                            else if(eCommerceList[b].code === 'multi_bank_ocr'){
                                return 1;
                            }
                            else{
                                return 0;
                            }
                        })
                        .map((partnerIndex) => {
                            const partner = eCommerceList[partnerIndex];
                            return (
                                <div key={partner.code} className={`partner-item ${partnerIndex == selectedPartner ? 'partner-item-selected' : ''}`} onClick={() => changePartner(partnerIndex, eCommerceList[partnerIndex].selectedId ? eCommerceList[partnerIndex].selectedId : 0)}>
                                    <img src={require(`${partner.icon}`)} alt={`${partner.code}_logo`} width={partner.iconWidth} className='partner-logo'/>
                                </div>
                            );
                        })
                }
                {/* {
                    selectedPartner !== undefined && selectedId !== undefined && eCommerceList[selectedPartner] !== undefined && 'code' in eCommerceList[selectedPartner] && eCommerceList[selectedPartner].code !== undefined && eCommerceList[selectedPartner].code == 'multi_bank_ocr' && !eCommerceList[selectedPartner].data[selectedId].excelLink ? (null) :(selectedCategory =='Bank E-statement Upload' && <div className={`export-csv-btn analysis-data-btn download-pdf-btn ${dashboardStatus == 'downloadingPDF' ? 'export-csv-btn-downloading' : ''}`} title="Generate individual banks data to get that bank analysis" onClick={() => downloadExcelData()}>Generate {eCommerceList[selectedPartner] !== undefined ? eCommerceList[selectedPartner].code.toUpperCase() : ''} Bank</div>)
                } */}
                {
                    (
                        selectedPartner !== undefined && 'code' in eCommerceList[selectedPartner] && eCommerceList[selectedPartner].code !== undefined && eCommerceList[selectedPartner].code !== 'multi_bank_ocr'
                    )
                    ?
                    selectedPartner !== undefined && selectedId !== undefined && eCommerceList[selectedPartner] !== undefined && 'isError' in eCommerceList[selectedPartner].data[selectedId] ? (null) :(selectedCategory =='Bank E-statement Upload' && <div className={`export-csv-btn analysis-data-btn download-pdf-btn ${dashboardStatus == 'downloadingPDF' ? 'export-csv-btn-downloading' : ''}`} title="Generate individual banks data to get that bank analysis" onClick={() => downloadExcelData()}>Get {eCommerceList[selectedPartner] !== undefined ? eCommerceList[selectedPartner].code.toUpperCase() : ''} analysis</div>)
                    :
                    (null)
                }
            </div>
            {
                selectedPartner !== undefined && selectedId !== undefined && isUserID && selectedCategory !== "E-KYC" && (
                    <div className='dopdown-id-div'>
                        <label className='dropdown-label'>Connection</label>
                        <select id="user-id" className='dropdown-id-list' value={selectedId} onChange={(e) => changeId(e.target.value)}>
                            {
                                eCommerceList[selectedPartner].data.filter(idData => !idData.isLogsID).map((idData, index) => {
                                    let date = new Date(idData.created_at).toLocaleTimeString("en-us", options);
                                    date = date == 'Invalid Date' ? idData.created_at : date;
                                    return (
                                            <option key={idData.id} value={index}>
                                                {idData.connectionName == 'NA' ? `Session ${index+1} (${date})` : idData.connectionName}
                                            </option>
                                        );
                                })
                            }
                        </select>
                    </div>
                )
            }
            {
                selectedPartner !== undefined && selectedId !== undefined && !eCommerceList[selectedPartner].isError && eCommerceList[selectedPartner].isPartnerInfoShowing && eCommerceList[selectedPartner].data[selectedId] && eCommerceList[selectedPartner].data[selectedId].partnerInfo && tables && (
                    <div className='partner-info'>
                        {
                            eCommerceList[selectedPartner].data[selectedId].partnerInfo.length > 1 && (
                                <div className="tab-category">
                                    {
                                        eCommerceList[selectedPartner].data[selectedId].partnerInfo.map((obj, index) => {
                                            return (
                                                <div key={obj.tableName} className={`tab-category-item ${index === selectedPartnerInfoTable ? 'tab-category-item-selected' : ''}`} onClick={() => changePartnerInfoTable(index)}>
                                                    {obj.tableName}
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            )  
                        }
                        {
                            (
                                <div className="account-info">
                                    <table>
                                        <tbody>
                                            {
                                                eCommerceList[selectedPartner].data[selectedId].partnerInfo[selectedPartnerInfoTable].table.map((row, index) => {
                                                    return (
                                                        <tr key={`${eCommerceList[selectedPartner].data[selectedId].partnerInfo[selectedPartnerInfoTable].tableName}_${index}`} className="account-info-column">
                                                            {
                                                                row.map((column, index) => {
                                                                    return (
                                                                        <td key={column.name} className={`account-info-item`}>
                                                                            <div className='account-info-item-div'>
                                                                                {
                                                                                    column.icon && <img src={require(`${column.icon}`)} alt={`${column.name}-icon`} className='account-info-icon'/>
                                                                                }
                                                                                <div className="account-info-item-heading">{column.name}:</div>
                                                                                <div className="account-info-item-text">{`${column.value}`}</div>
                                                                            </div>
                                                                        </td>
                                                                    );
                                                                })
                                                            }
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            )
                        }
                    </div>
                )
            }
            {
                selectedPartner !== undefined && selectedId !== undefined && eCommerceList[selectedPartner].isBanking && (
                    <div className="analysis-data">
                        {
                            selectedTable && <div className='info-small'>* For detailed view please download the excel</div>
                        }
                         {/* <div className={`export-csv-btn analysis-data-btn download-pdf-btn ${dashboardStatus == 'downloadingPDF' ? 'export-csv-btn-downloading' : ''}`} onClick={() => downloadExcelData()}>Consolidated Statement</div> */}
                        <div className={`export-csv-btn analysis-data-btn download-pdf-btn ${dashboardStatus == 'downloadingPDF' ? 'export-csv-btn-downloading' : ''}`} onClick={() => downloadPDF()}>Download Bank Statement</div>
                    </div>
                )
            }
            {
                selectedPartner !== undefined && selectedId !== undefined && (eCommerceList[selectedPartner].isShowRefreshButton || eCommerceList[selectedPartner].isShowSummaryButton || eCommerceList[selectedPartner].isShowDeleteButton ) && eCommerceList[selectedPartner].data[selectedId].excelLink && (
                    <div className="analysis-data">
                        {
                            eCommerceList[selectedPartner].isShowDeleteButton && <div title="Caution: Clicking this button will remove all partners from the current session." className={`export-csv-btn-dlt`} onClick={() => deleteData()}>Delete Partners</div>
                        }
                        {
                            eCommerceList[selectedPartner].isShowSummaryButton && <div className={`export-csv-btn analysis-data-btn ${dashboardStatus == 'requesting-summary' ? 'export-csv-btn-downloading' : ''}`} onClick={() => summaryData()}>Generate Summary</div>
                        }
                        {
                            eCommerceList[selectedPartner].isShowRefreshButton && <div className={`export-csv-btn analysis-data-btn ${dashboardStatus == 'requesting-refresh' ? 'export-csv-btn-downloading' : ''}`} onClick={() => refreshData('fclick')}>Refresh Data</div>
                        }
                    </div>
                )
            }
            { selectedCategory !== 'E-KYC' && (<div className="tab-category tab-category-table">
                {
                    tables && Object.keys(tables).filter(key => !(eCommerceList[selectedPartner].formatting[key] && eCommerceList[selectedPartner].formatting[key].isHidden)).map(key => {
                        const formattedKey = key.split('_').map(key => key.charAt(0).toUpperCase() + key.slice(1)).join(' ');
                        return (
                            <div key={key} className={`tab-category-item ${key == selectedTable ? 'tab-category-item-selected' : ''}`} onClick={() => changeTable(key)}>
                                {formattedKey}
                            </div>
                        );
                    })
                }
            </div>)}
            <div className="partner-table">
                {
                    selectedPartner !== undefined && selectedId !== undefined && !eCommerceList[selectedPartner].data[selectedId].isError 
                    ? (
                        selectedTable && tables && tables[selectedTable].rows && tables[selectedTable].rows.length ? (
                            <table className='table-1'>
                                <thead>
                                    <tr className='table-row-header'>
                                        <th className='table-cell'>Index</th>
                                        {
                                            Object.keys(tables[selectedTable].headers).map(key => {
                                                const width = eCommerceList[selectedPartner].formatting[selectedTable] && eCommerceList[selectedPartner].formatting[selectedTable].fields[`${key}_width`];
                                                const formattedKey = key.split('_').map(key => key.charAt(0).toUpperCase() + key.slice(1)).join(' ');
                                                return (
                                                    <th key={key} className='table-cell' style={width ? {minWidth: width} : {}}>{formattedKey}</th>
                                                );
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        tables[selectedTable].rows.slice((pageNo - 1) * rowsPerPage, (pageNo) * rowsPerPage).map((row, index) => {
                                            return (
                                                <tr key={`${row[Object.keys(row)[0]]}_${index}`}className='table-row-body'>
                                                    <td className='table-cell-body'>{((pageNo - 1) * rowsPerPage) + index+1}</td>
                                                    {
                                                        Object.keys(tables[selectedTable].headers).map(key => {
                                                            let value = row[key];
                                                            if(eCommerceList[selectedPartner].formatting[selectedTable] && eCommerceList[selectedPartner].formatting[selectedTable].fields[key]){
                                                                value = eCommerceList[selectedPartner].formatting[selectedTable].fields[key](row[key]);
                                                            }
                                                            if(value === null){
                                                                value = 'none';
                                                            }
                                                            else if(value === undefined){
                                                                value = '';
                                                            }
                                                            return (
                                                                <td key={key} className='table-cell-body'>
                                                                    <div className='table-cell-body-div' onClick={(e) => { e.target.style.whiteSpace = e.target.style.whiteSpace == 'normal' ? 'nowrap' : 'normal'}}>
                                                                        {value}
                                                                    </div>
                                                                </td>
                                                            );
                                                        })
                                                    }
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        ) : (
                            selectedCategory !== 'E-KYC' && (
                                <div className='error-table'>
                                { 
                                    isLoading 
                                    ? '' 
                                    : ( selectedPartner !== undefined && selectedId !== undefined && !eCommerceList[selectedPartner].data[selectedId].isError && eCommerceList[selectedPartner].data[selectedId].errorMessage
                                        ? ( eCommerceList[selectedPartner].data[selectedId].fileName 
                                            ? (
                                                <div>
                                                    {eCommerceList[selectedPartner].data[selectedId].errorMessage} 
                                                    <div className='error-msg-div'>
                                                        Filename: {eCommerceList[selectedPartner].data[selectedId].fileName}
                                                    </div>
                                                </div>
                                            )
                                            : eCommerceList[selectedPartner].data[selectedId].errorMessage
                                        )
                                        : (selectedPartner !== undefined && selectedId !== undefined && !eCommerceList[selectedPartner].data[selectedId].excelLink 
                                            ? 
                                            (selectedCategory =='Bank E-statement Upload') 
                                                ?
                                                (eCommerceList[selectedPartner].code == 'multi_bank_ocr')
                                                    ?
                                                    <div>
                                                        Data found 
                                                        <div className='error-msg-div'>
                                                            Please wait for analysis to get completed!
                                                        </div>
                                                    </div>
                                                    :
                                                    <div>
                                                        Data found
                                                        <div className='error-msg-div'>
                                                            Please click on "Get {eCommerceList[selectedPartner] !== undefined ? eCommerceList[selectedPartner].code.toUpperCase() : ''} analysis" button to generate excel!
                                                        </div>
                                                    </div>
                                                :
                                                (
                                                    <div>
                                                        Data not found 
                                                        <div className='error-msg-div'>
                                                            Connection Status: Tokens available
                                                        </div>
                                                    </div>
                                                )
                                            : (selectedPartner !== undefined && eCommerceList[selectedPartner].isShowOnlyDownload ? 'Please download the excel' : 'NA'))
                                    ) 
                                }
                            </div>
                        )
                            )
                    )
                    : ( isLoading || selectedPartner === undefined || selectedId === undefined
                        ? ''
                        : ( 
                            <div className='error-div'>
                                Connection Status: &#160;
                                {
                                    eCommerceList[selectedPartner].data[selectedId].errorMessage && (
                                        <span className='error-msg-div'>
                                            {
                                                typeof eCommerceList[selectedPartner].data[selectedId].errorMessage == 'object' && eCommerceList[selectedPartner].data[selectedId].errorMessage !== null 
                                                    ? (
                                                    Object.keys(eCommerceList[selectedPartner].data[selectedId].errorMessage).map(key => {
                                                        return (
                                                            <div key={key} className='error-message-object'>
                                                                {key} - {eCommerceList[selectedPartner].data[selectedId].errorMessage[key]}
                                                            </div>
                                                        );
                                                    })
                                                    ) 
                                                    : eCommerceList[selectedPartner].data[selectedId].errorMessage
                                            }
                                        </span>
                                    )
                                }
                            </div>
                        )
                    )
                }
            </div>
            {
                selectedTable && tables && Math.ceil(tables[selectedTable].rows.length / rowsPerPage) > 1 && (
                    <div className='pagination'>
                        <button className='pagination-prev' onClick={() => {if(pageNo > 1){changePage(pageNo-1)}}}>&lt;</button>
                        {
                            pageNo - 1 > 1 && <button className='pagination-btn' onClick={() => changePage(1)}>1</button>
                        }
                        {
                            pageNo - 1 > 2 && <div className='pagination-dot'>...</div>
                        }
                        {
                            [pageNo - 1, pageNo, pageNo + 1].filter(pageNoI => pageNoI > 0 && pageNoI <= Math.ceil(tables[selectedTable].rows.length / rowsPerPage)).map(pageNoI => {
                                return (
                                    <button key={pageNoI} className={`pagination-btn ${pageNoI == pageNo ? 'pagination-btn-selected' : ''}`} onClick={() => {if(pageNoI === pageNo) return; changePage(pageNoI);}}>{pageNoI}</button>
                                );
                            })
                        }
                        {
                            pageNo + 1 < Math.ceil(tables[selectedTable].rows.length / rowsPerPage) - 1 && <div className='pagination-dot'>...</div>
                        }
                        {
                            pageNo + 1 < Math.ceil(tables[selectedTable].rows.length / rowsPerPage) && <button className='pagination-btn' onClick={() => changePage(Math.ceil(tables[selectedTable].rows.length / rowsPerPage))}>{Math.ceil(tables[selectedTable].rows.length / rowsPerPage)}</button>
                        }
                        <button className='pagination-next' onClick={() => {if(pageNo < Math.ceil(tables[selectedTable].rows.length / rowsPerPage)){changePage(pageNo+1)}}}>&gt;</button>
                    </div>
                )
            }
            {
                showModal && <Modal message={showModal.message} timeOuts= {showModal.timeOut} closeModal={() => {setShowModal();setIsDownloadStatement(false);setIsSummaryStmt(false)}} downloadExcel={downloadExcel}/>
            }
            {
                showDeleteModal && <DeleteModal message={showDeleteModal.message} deletePdata= {deletePdata} closeModal={() => {setShowDeleteModal();setIsDeleted(false)}} isdeleted={isdeleted} />
            }
            {
                refreshModal && <RefreshModal message={refreshModal.message} refreshData={refreshData} closeModal={() => {setRefreshModal()}}/>
            }
            { isLoading && <FullPageLoader /> }
        </div>
     );
}
 
export default PartnersDetail;