import { useEffect, useState } from "react";
import axios from 'axios';
// import { idList1, idList2 } from './TestingData';
import FullPageLoader from "./FullPageLoader";

const ListAccount = (props) => {
    const { searchParams, data, setData, options, currentPage, setCurrentPage, limit, changeRoute, baseURL, sortedColumn, setSortedColumn } = props;

    let partners = ['gopay', 'multi_bank_ocr', 'bca', 'bri', 'bni', 'mandiri', 'ocbc', 'cimb', 'permata', 'danamon', 'btn', 'telco', 'shopee', 'shopeeverifybank', 'tokopedia_api', 'lazada', 'blibli', 'xero', 'jurnal', 'accurate', 'quickbooks', 'mokapos', 'google_location', 'instagram', 'bankverification', 'tiktok', 'identity'];
    
    const [isLoading, setLoader] = useState(false);

    const [isError, setIsError] = useState();

    const getDataCount = async () => {
        setLoader(true);
        const config = {
            method: 'GET',
            baseURL,
            url: '/backoffice/v2/users/count',
            params: {
                apikey: searchParams.get('apikey'),
                developer_email: searchParams.get('developer_email'), 
                app_id: searchParams.get('app_id'),
                limit
            },
            headers: { 
                'accept': 'application/json'
            }
        };
        try{
            let response = await axios(config);
            response = response.data;

            let data = response.id_list.map(id => {
                return { id };
            });
            data = response.count == limit ? [{}] : [{}].concat(data);

            data[0].data = await getPageData();

            setData(data);
        }
        catch(e){
            console.log('error in fetching data count', e);
            setIsError(true);
        } 
        setLoader(false);
    };

    const getPageData = async next => {
        setLoader(true);
        const config = {
            method: 'GET',
            baseURL,
            url: '/backoffice/v2/',
            params: {
                apikey: searchParams.get('apikey'),
                developer_email: searchParams.get('developer_email'),
                app_id: searchParams.get('app_id'),
                limit,
                next
            },
            headers: { 
                'accept': 'application/json'
            }
        };
        try{
            let response = await axios(config);
            response = response.data;
            setLoader(false);
            return response;
        }
        catch(e){
            console.log(`error in fetching ${searchParams.get('app_id')}`, e);
            setLoader(false);
            return [];
        } 
    };

    const changePage = async newPage => {
        const newData = [...data];
        newData[newPage - 1] = {...newData[newPage - 1]};
        if(!data[newPage - 1].data){
            newData[newPage - 1].data = await getPageData(newData[newPage - 1].id);
            setData(newData);
        }
        if(sortedColumn.column){
            // newData[newPage - 1].data = [...newData[newPage - 1].data];
            sortData(newData[newPage - 1].data, sortedColumn.column, sortedColumn.isAscending);
            setData(newData);
        }
        setCurrentPage(newPage);
    }

    const getConnectionStatus = row => {
        if(row.connected_partners && row.connected_partners.filter(partner => partners.find(partnerI => (!Array.isArray(partner) && partnerI === partner) || (Array.isArray(partner) && partnerI === partner[0]))).length){
            return 'Success';
        }
        else if(row.incomplete_partners && row.incomplete_partners.filter(partner => partners.find(partnerI => (!Array.isArray(partner) && partnerI === partner) || (Array.isArray(partner) && partnerI === partner[0]))).length){
            return 'Review';
        }
        else{
            return 'Failed';
        }
    };

    const sortData = (data, column, isAscending) => {
        data.sort((obj1, obj2) => {
            let value1 = obj1.data[column]; 
            let value2 = obj2.data[column];
            if(column == 'Connection Status'){
                value1 = getConnectionStatus(obj1.data);
                value2 = getConnectionStatus(obj2.data);
            }
            if(value1 < value2){
                return isAscending ? -1 : 1;
            }
            else if(value1 > value2){
                return isAscending ? 1 : -1;
            }
            else{
                return 0;
            }
        });
    };

    const sortColumn = column => {
        const isAscending = column == sortedColumn.column ? !sortedColumn.isAscending : sortedColumn.isAscending;
        sortData(data[currentPage - 1].data, column, isAscending);
        const newData = [...data];
        newData[currentPage - 1] = {...newData[currentPage - 1]};
        newData[currentPage - 1].data = [...newData[currentPage - 1].data];
        setData(newData);
        setSortedColumn({column, isAscending});
    };

    useEffect(() => {
        if(!data.length){
            getDataCount();
            // setData(idList2);
        }
        let scrollPosition = sessionStorage.getItem('scrollPosition');
        if(scrollPosition){
            scrollPosition = JSON.parse(scrollPosition);
            document.getElementsByClassName("table-div-2")[0].scrollTo(parseInt(scrollPosition.x, 10), parseInt(scrollPosition.y, 10));
            sessionStorage.removeItem('scrollPosition');
        }
    }, []);

    if(isError){
        return (
            <div className='error-div-1'>Error: Please check paramters(apikey, developer_email and app_id)</div>
        );
    }
    return ( 
        <div>
           <div className="heading-div">
                <div className="heading-1">Statement</div>
                {/* <div className="">Search icon</div> */}
                {/* <div className="export-csv-btn">Export CSV</div> */}
            </div>
            <div className="table-container">
                {
                    currentPage <= data.length && data[currentPage - 1].data && (
                        <table className='table-1'>
                            <thead>
                                <tr className='table-row-header'>
                                    <th className='table-cell'>Index</th>
                                    <th className='table-cell sortable-cell' onClick={() => sortColumn('created_at')}>
                                        <div className="header-cell-div header-cell-div-cr">
                                            <span>Created</span>
                                            <img src={require("./images/left-arrow-1.png")} alt="back_icon" className={`sorting-arrow ${!sortedColumn.isAscending ? 'sorting-arrow-down' : ''}`} style={sortedColumn.column == 'created_at' ? {} : {display: "none"}}/>
                                        </div>
                                    </th>
                                    <th className='table-cell'>User ID</th>
                                    <th className='table-cell sortable-cell' onClick={() => sortColumn('Connection Status')}>
                                        <div className="header-cell-div header-cell-div-cs">
                                            <span>Connection Status</span>
                                            <img src={require("./images/left-arrow-1.png")} alt="back_icon" className={`sorting-arrow ${!sortedColumn.isAscending ? 'sorting-arrow-down' : ''}`} style={sortedColumn.column == 'Connection Status' ? {} : {display: "none"}}/>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data[currentPage - 1].data.map((row, index) => {
                                        const created = new Date(row.data.created_at).toLocaleTimeString("en-us", options);
                                        const connectionStatus = getConnectionStatus(row.data);
                                        return (
                                            <tr key={row.user_id} className={`table-row-body ${connectionStatus == 'Success' || connectionStatus == 'Review' ? 'table-row-hover' : ''}`} onClick={() => {if(connectionStatus == 'Success' || connectionStatus == 'Review'){ changeRoute('userid-v', row.user_id); }}}>
                                                <td className='table-cell-body'>{((currentPage - 1) * limit) + index+1}</td>
                                                <td className='table-cell-body'>{created}</td>
                                                <td className='table-cell-body'>{row.user_id}</td>
                                                <td className='table-cell-body'>
                                                    <div className={`table-cell-body-div-${connectionStatus.toLowerCase()}`}>
                                                        {connectionStatus == 'Failed' ? 'No Connections' : connectionStatus}
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    )
                }
            </div>
            {
                data.length > 1 && (
                    <div className='pagination'>
                        <button className='pagination-prev' onClick={() => {if(currentPage > 1){changePage(currentPage-1)}}}>&lt;</button>
                        {
                            currentPage - 1 > 1 && <button className='pagination-btn' onClick={() => changePage(1)}>1</button>
                        }
                        {
                            currentPage - 1 > 2 && <div className='pagination-dot'>...</div>
                        }
                        {
                            [currentPage - 1, currentPage, currentPage + 1].filter(currentPageI => currentPageI > 0 && currentPageI <= data.length).map(currentPageI => {
                                return (
                                    <button key={currentPageI} className={`pagination-btn ${currentPageI == currentPage ? 'pagination-btn-selected' : ''}`} onClick={() => {if(currentPageI !== currentPage){changePage(currentPageI)}}}>{currentPageI}</button>
                                );
                            })
                        }
                        {
                            currentPage + 1 < data.length - 1 && <div className='pagination-dot'>...</div>
                        }
                        {
                            currentPage + 1 < data.length && <button className='pagination-btn' onClick={() => changePage(data.length)}>{data.length}</button>
                        }
                        <button className='pagination-next' onClick={() => {if(currentPage < data.length){changePage(currentPage+1)}}}>&gt;</button>
                    </div>
                )
            }
            { isLoading && <FullPageLoader /> }
        </div>
     );
}
 
export default ListAccount;