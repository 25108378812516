import { useEffect, useState } from 'react';
import './Modal.css';

const RefreshModal = (props) => {
    const { message, refreshData, closeModal, isdeleted} = props;

      const handleClick = () => {
        closeModal();
      };
    return ( 
        <div id="myModal" className="modal" onClick={(e) => {return; if(e.target == document.getElementById("myModal")) closeModal(); }}>
            <div className="modal-content">
            <span className="close" style={{marginTop: '-20px'}} onClick={handleClick}>&times;</span>
                {
                    !isdeleted && (
                message.isError
                    ? `Error: ${message.detail}`
                    : (
                    <>
                        {message.message1}
                        <div className={`export-csv-btn download-excel-modal`} style={{marginTop:'30px',fontSize:'15px'}} onClick={() => refreshData()}> 
                        Refresh Data
                        </div>                 
                    </>
                    ) )
                }

                {
                    isdeleted && ( 
                        <div>{message.message2} </div>                                    
                    )
                }
               
            </div>
        </div>
     );
}
 
export default RefreshModal;